<template>
  <article class="message is-info">
    <div class="message-body">
      <div style="padding: 10px 5px">
        <small>Nombre: <b>{{ data.full_name }}</b></small><br>
        <small>Edad: {{ data.age }} años</small><small style="margin-left: 1em;">Clave: <b>{{ data.code }}</b></small><br>
        <small>Género: {{ data.gender_str }}</small><small style="margin-left: 1em;">F/N: {{ data.birthday }}</small><br>
        <small>Correo: {{ data.email }}</small><br>
        <small>Teléfono: {{ data.phone }}</small><br>
        <small v-if="data.cell_phone">Whatsapp: {{ data.cell_phone }}</small><br>
        <template v-if="data.employee">
          <small>Empleado: {{ data.employee }}</small><br>
          <small>Beneficiario: {{ data.beneficiary }}</small><br>
        </template>
        <div class="custom-button">
          <button v-if="!readOnly"
                  class="button is-small is-danger"
                  type="button"
                  @click="$store.dispatch('order/removePatient'); $store.dispatch('setShowModalSelectPatient', true);">
                <span>
                    Elegir otro paciente
                </span>
            <b-icon icon="times"></b-icon>
          </button>
          <button class="button is-warning is-small"
                  v-if="allowEdit"
                  type="button" @click="onEditPatient(data.code)">
            <span>Editar Paciente</span>
            <b-icon icon="edit"></b-icon>
          </button>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import ModalEditPatient from "./ModalEditPatient";

export default {
  name: "DataOrderPatient",
  props: {
    data: Object,
    readOnly: Boolean,
    allowEdit: Boolean
  },
  methods: {
    onEditPatient(patient) {
      let vm = this;
      this.$buefy.modal.open({
        parent: this,
        component: ModalEditPatient,
        hasModalCard: true,
        trapFocus: true,
        props: {
          patient
        },
        events: {
          updatePatient(data) {
            vm.onSetPatient(data);
          }
        }
      })
    },
    onSetPatient(patient = {} ) {
      this.$emit('update:data', patient)
      this.$store.dispatch('order/setPatient', patient);
    }
  }
}
</script>

<style scoped>

</style>
