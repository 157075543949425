<template>
  <div class="is-flex is-flex-wrap-wrap">
    <div class="" v-for="(sample, key) in items" style="margin: 10px;">
      <div class="sample-box">
        <b-field class="is-center" style="text-align: center">
          <b-checkbox
              @input="(value)=>onChange(key, value)"
              v-model="items[key].value"
              :true-value="true"
              :false-value="false"
              :disabled="!$alfalab.allowRegistrySamplesWithOrdersign($auth.user(), data)"
          >
            {{ data.samples[key].container_shortname }} <br/>
            <div style="display: flex">
              <b-tooltip multilined>
                <div style="font-size: 9px; margin-right: 25px">
                  <span v-if="data.samples[key].studies" v-html="data.samples[key].studies.shortnames.replaceAll(',', '<br>')"></span>
                </div>
                <template v-slot:content>
                  <span
                      v-if="data.samples[key].studies"
                      v-html="data.samples[key].studies.names.replaceAll(',', '<br>') "/>
                </template>
              </b-tooltip>
              <div><img :alt="data.samples[key].container" :src="data.samples[key].image" height="100px" style="height: 100px"/></div><br />
            </div>
            {{ data.samples[key].sample_material }} <br/>
            <b-tooltip>
              <template v-slot:content>
                  <span
                      v-html="renderTooltipDates(data.samples[key])"/>
              </template>
              {{items[key].date1}}
            </b-tooltip>
          </b-checkbox>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderSamples",
  props: {
    data: Object
  },
  data(){
    return {
      items: []
    }
  },
  computed: {
  },
  mounted() {
    this.items = this.data.samples.map(item => {
      return {value: item.date1 !== '0001-01-01 00:00:00', date1: item.date1};
    });
  },
  methods: {
    onChange(key, value){
      this.$buefy.dialog.confirm({
        title: value ? 'Marcar como recibida' : 'Marcar como no recibida',
        message: '¿Estas seguro de realizar la modificación?',
        confirmText: 'Si.',
        cancelText: 'No, cancelar.',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.onUpdate(key, value),
        onCancel: () => {
          const items = this.items.slice(0);
          items[key].value = !value;
          this.items = items;
        }
      })
    },
    onUpdate(key, value){
      this.$loader.show();
      let d = this.$options.filters.date(new Date(), 'YYYY-MM-DD H:m:s');
      if(!value){
        d = '0001-01-01 00:00:00';
      }
      this.$http.put(`/samples/${this.data.samples[key].id}`, {
        date1: d
      }).then(({data}) => {
        this.$toast.success(data.message);
        const items = this.items.slice(0);
        items[key].date1 = d;
        this.items = items;
      }).catch(errors => {
        this.$alfalab.showError(errors);
        const items = this.items.slice(0);
        items[key].value = !value;
        this.items = items;
      }).finally(() => {
        this.$loader.hide();
      })
    },
    renderTooltipDates(sample){
      return `${sample.date1 !== '0001-01-01 00:00:00' && sample.date1 !== '0001-00-00 00:00:00' ? 'Recibida en sucursal<br>': ''}` +
          `${sample.date2 !== '0001-01-01 00:00:00' && sample.date2 !== '0001-00-00 00:00:00' ? 'Salio de Sucursa: '  + sample.date2  + '<br>': ''}` +
          `${sample.date3 !== '0001-01-01 00:00:00' && sample.date3 !== '0001-00-00 00:00:00' ? 'Recibida en centrifugación: '  + sample.date3  + '<br>': ''}` +
          `${sample.is_complete ? 'Concluida': ''}`;
    }
  }
}
</script>

<style scoped>

</style>
