<template>
  <b-field label="Tarjeta de descuento">
    <b-autocomplete :placeholder="placeholder"
                    :data="filteredDataObj"
                    open-on-focus
                    clearable
                    :field="optionText"
                    v-model="option"
                    @select="onSelectOption"
                    >

    </b-autocomplete>
  </b-field>
</template>

<script>
export default {
  name: "SelectDiscountCard",
  props: {
    placeholder: {
      type: String,
      default: 'Selecciona una tarjeta descuento'
    },
    options: {
      type: Array,
      default() {
        return this.$store.state.main.discountCards || [];
      }
    },
    optionValue: {
      type: String,
      default: 'id',
    },
    optionText: {
      type: String,
      default: 'name',
    },
    value: {},
    type : {
      type: String,
      default: 'order'
    }
  },
  data() {
    return {
      option: ''
    }
  },
  computed: {
    filteredDataObj() {
      return this.options.filter(option => {
        return (
            option[this.optionText]
                .toString()
                .toLowerCase()
                .indexOf(this.option.toLowerCase()) >= 0
        )
      })
    }
  },
  mounted() {
    this.$store.subscribeAction((action, state) => {
      if(this.type === 'quotation') {

      }
    });
  },
  methods: {
    onSelectOption(val) {
      if (val != null) {
          this.$emit('input', val[this.optionValue]);
      } else {
        if(this.type === 'order'){
          this.$store.dispatch('order/removeDiscount');
        }else{
          this.$emit('remove-discount-card');
        }
      }
    }
  }
}
</script>

<style scoped>

</style>