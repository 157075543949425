<template>
  <div class="study-configs">
    <b-tooltip :label="active ? 'Muestra recibida' : 'Muestra no recibida'">
    <b-switch
        v-if="canNoSample"
        @click.native="(e) => {e.cancelBubble=true}"
        v-model="active"></b-switch>
    </b-tooltip>
    <b-tooltip label="Editar nombre alternativo" position="is-right">
      <b-button icon-left="edit" @click="handleClick"/>
    </b-tooltip>
    <span v-if="!enableClick">
      {{ showName ? element.name : element.code }}
    </span>
    <span v-else>
      <a @click="$emit('openDetail')">{{ showName ? element.name : element.code }}</a>
    </span>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "StudyConfigs",
  props: {
    element: Object,
    showName: {
      default: false,
      type: Boolean
    },
    enableClick: {
      default: false,
      type: Boolean
    },
    order: Object,
    canNoSample: Boolean,
  },
  computed: {
    active: {
      set(value) {
        this.$store.dispatch('order/setNoReceive', {
          code: this.element.code,
          status: !value
        })
      },
      get() {
        return !this.$store.state.order.noRecieve.includes(this.element.code)
      }
    },
    ...mapGetters('order', ['readOnly']),
    ...mapState('order', ['noRecieve'])
  },
  methods: {
    handleClick(e) {
      e.cancelBubble = true;
      this.$buefy.dialog.prompt({
        message: `Nombre alternativo`,
        inputAttrs: {
          placeholder: "Nobre alternativo",
          value: this.$store.state.order.alternativeNames[this.element.code] ? this.$store.state.order.alternativeNames[this.element.code] : this.element.name,
        },
        trapFocus: true,
        onConfirm: value => {
          const name = this.$store.state.order.alternativeNames[this.element.code] ? this.$store.state.order.alternativeNames[this.element.code] : this.element.name;
          this.$store.dispatch('order/setAlternativeName', {
            code: this.element.code,
            value
          })
          if (this.readOnly) {
            this.$loader.show();
            const orderNew = this.order.order_new;
            console.log('order-new', orderNew)
            this.$http.post(`/order/${orderNew}/alternative-names`, {
              value: name,
              code: this.element.code
            }).finally(() => {
              this.$loader.hide();
            })
          }
        }
      });
    }
  }
}
</script>

<style scoped>
.study-configs {
  display: inline;
}
</style>