<template>
    <b-field grouped group-multiline>
        <b-field label="Rango de Fechas"
                 style="min-width: 200px;">
            <b-datepicker
                    :max-date="new Date()"
                    expanded
                    placeholder="Selecciona un rango de fechas"
                    v-model="dates"
                    range>
            </b-datepicker>
        </b-field>
        <b-field label="Tipo de estadistica"
                 style="min-width: 150px;">
            <b-select expanded v-model="params.type">
                <option v-for="item in typeOptions"
                        :value="item.name">
                    {{ item.label }}
                </option>
            </b-select>
        </b-field>
        <b-field>
            <button class="button is-primary" type="button" @click="getData">
                <b-icon icon="search"></b-icon>
            </button>
        </b-field>
    </b-field>
</template>

<script>
    export default {
        name: "FormGetStats",
        props: {
            stats: Object,
            loading: Boolean,
        },
        data() {
            return {
                dates: [
                    new Date(this.$moment().subtract(1, 'week').format()), new Date()
                ],
                typeOptions: [
                    {
                        name: 'order',
                        label: 'Órdenes'
                    }
                ],
                params: {
                    type: 'order'
                },
                minDate: new Date(this.$moment().subtract(2, "years").format())
            }
        },

        mounted() {
            this.getData();
        },
        methods: {
            async getData() {
                this.$emit('update:loading', true);
                let params = {
                    ...this.params,
                    start_date: this.dates[0],
                    end_date: this.dates[1],
                }
                await this.$http.get('stats', {
                    params
                }).then(({data}) => {
                    this.$emit('update:stats', data);
                }, ({response}) => {
                    let data = response.data || {};
                    this.$toast.error(data.message);
                });
                setTimeout(() => {
                    this.$emit('update:loading', false);
                }, 1000);
            }
        }
    }
</script>

<style scoped>

</style>