<template>
  <div>
    <div class="columns">
      <div class="column is-10">
        <b-field label="Toma">
          <b-autocomplete
              v-model="name"
              placeholder=""
              :open-on-focus="true"
              :data="filteredDataObj"
              field="name"
              @select="option => {if(option){($store.dispatch('order/setForeignWork', option.id))}else{$store.dispatch('order/setForeignWork', 0)}}"
              :clearable="true"
          >
          </b-autocomplete>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Presentado">
          <b-checkbox v-model="foreignWorkCheck" />
        </b-field>
      </div>
    </div>
    <b-field v-if="canUpdate">
      <button class="button is-primary is-rounded"
              @click="onUpdate"
              type="button">
        Actualizar Toma
      </button>
    </b-field>
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
  name: "ForeignWork",
  props: {
    data: Object,
    canUpdate: Boolean,
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      foreignWorkCatalog : state => state.main.foreignWorks
    }),
    filteredDataObj() {
      return this.foreignWorkCatalog.filter(option => {
        return (
            option.name
                .toString()
                .toLowerCase()
                .indexOf(this.name.toLowerCase()) >= 0
        )
      })
    },
    foreignWorkCheck: {
      get () {
        return this.$store.state.order.foreignWorkCheck;
      },
      set (value) {
        this.$store.dispatch('order/setForeignWorkCheck', value);
      }
    },
    name: {
      get () {
        return this.$store.state.order.foreignWorkName;
      },
      set (value) {
        this.$store.dispatch('order/setForeignWorkName', value);
      }
    }
  },
  methods: {
    onUpdate(){
      this.$loader.show();
      this.$http.put(`/order-update-observation/${this.data.id}`, {
        foreignWork: this.$store.state.order.foreignWork,
        foreignWorkCheck: this.foreignWorkCheck,
        action: 'updateForeignWork'
      }).then(({data}) => {
        this.$loader.hide();
        this.$toast.success(data.message);
      }, error => {
        this.$loader.hide();
      });
    },
  }
}
</script>

<style scoped>

</style>
