<template>
    <div>
        <div class="section-title">
            Estadisticas
        </div>
        <div class="box">
            <form-get-stats
                    :stats.sync="stats"
                    :loading.sync="loading">
            </form-get-stats>
        </div>
        <div class="card">
            <div class="card-content">
                <div class="is-inline-block mx-auto" style="width: 100%; height: 100px;" v-if="loading">
                    <breeding-rhombus-spinner
                            :animation-duration="2000"
                            :size="65"
                            color="#ff1d5e"
                            class="mx-auto"
                    />
                </div>

                <div class="columns" v-else>
                    <div class="column">
                        <order-stats-graph :data="stats">
                        </order-stats-graph>
                    </div>
                    <div class="column is-6"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FormGetStats from "../components/stats/FormGetStats";
    import { BreedingRhombusSpinner } from 'epic-spinners'
    import OrderStatsGraph from "../components/stats/order/OrderStatsGraph";

    export default {
        name: "Stats",
        components: {OrderStatsGraph, FormGetStats, BreedingRhombusSpinner},
        data() {
            return {
                stats: {},
                loading: true
            }
        }
    }
</script>

<style scoped>

</style>