<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Seleccion de estudios</p>
    </header>
    <section class="modal-card-body">
      <b-table
          :data="data"
          :columns="columns"
          :selected.sync="selected"
          :paginated="paginated"
          :per-page="perPage"
          focusable
          ref="studies_table"
          searchable
          @dblclick="onSelect"
          :current-page="currentPage"
          sticky-header
          height="70vh"
          pagination-simple
          @keypress.enter.native="onEnterClickTable"
          @keyup.37.native="onKeyPressLeft"
          @keyup.39.native="onKeyPressRight"
          :backend-filtering="true"
          @filters-change="onFiltersChange"
      >
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon
                    icon="frown"
                    size="is-large">
                </b-icon>
              </p>
              <p>Lo sentimos no hay ningún estudio que coincida con tu busqueda.</p>
            </div>
          </section>
        </template>
      </b-table>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Cerrar</button>
      <button class="button is-primary" :disabled="data.length < 1" type="button" @click="onSelect(selected)">
        Seleccionar
      </button>
    </footer>
  </div>
</template>

<script>
export default {
  name: "ModalSelectStudy",
  props: {
    data: Array,
    perPage: {
      type: Number,
      default: 15
    },
    paginated: Boolean,
  },
  data() {
    return {
      currentPage: 1,
      columns: [
        {
          field: 'id',
          label: 'ID',
          width: '40',
          numeric: true
        },
        {
          field: 'code',
          label: 'Código',
          searchable: true,
        },
        {
          field: 'name',
          label: 'Nombre',
          searchable: true,
        },
      ],
      selected: {}
    }
  },
  mounted() {
    if (this.data.length) {
      this.selected = this.data[0];
      setTimeout(() => {
        this.$refs.studies_table.focus();
      }, 100);
    }
  },
  methods: {
    onSelect(data) {
      this.$parent.close();
      this.$emit('select', data)
    },
    onEnterClickTable() {
      this.onSelect(this.selected);
    },
    onKeyPressLeft() {
      if (this.data.length > this.perPage) {
        if (this.currentPage > 1) {
          this.currentPage -= 1;
        }
      }
    },
    onKeyPressRight() {
      if (this.data.length > this.perPage) {
        let pages = Math.ceil(this.data.length / this.perPage);
        if (this.currentPage < pages) {
          this.currentPage += 1;
        }
      }
    },
    onFiltersChange(params) {
      this.$emit('search', params);
    }
  }
}
</script>

<style scoped>

</style>
