<template>
    <b-modal :active="active" @close="$emit('update:active', false)" :width="640" scroll="keep">
        <form-modal-folio-imss
                @setData="val => $emit('setData', val)"
        ></form-modal-folio-imss>
    </b-modal>
</template>

<script>
    import FormModalFolioImss from "./FormModalFolioImss";
    export default {
        name: "ModalFolioImss",
        components: {FormModalFolioImss},
        props: {
            active: Boolean,
        }
    }
</script>

<style scoped>

</style>