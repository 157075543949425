<template>
  <div>
    <b-tabs v-model="activeTab">
      <b-tab-item :label="`Documentos`" v-if="data.docs.length > 0">
        <iframe v-for="doc in data.docs" :src="doc.url" frameborder="0" class="lapi-iframe"/>
      </b-tab-item>
      <b-tab-item :label="`Etiquetas`" v-if="data.labels.length > 0">
        <div v-for="label in labels">
          <img :src="`${label}`" alt="label">
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
export default {
  name: "ModalPreviewPrint",
  props: {
    data: Object,
  },
  data(){
    return {
      activeTab: 0,
      url: 'https://api.labelary.com/v1/printers/8dpmm/labels/2.25x.95/'
    }
  },
  computed: {
    labels() {
      const result = [];
      for(const i of this.data.labels){
        const count = (i.match(/[^]XA/g) || []).length / 2;
        for(let j = 0;j<count; j++){
          result.push(`${this.url}${j}/${i}`);
        }
      }
      return result;
    }
  }
}
</script>

<style scoped>
.lapi-iframe{
  width: 100%;
  height: 100vh;
}
</style>
