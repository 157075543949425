<template>
    <b-field :label="label">
        <b-select :value="value"
                  @input="val => $emit('input', val)"
                  :disabled="disabled"
                  expanded>
            <option v-for="item in defaultOptions"
                    :value="item[optionValue]">
                {{ item[optionText] }}
            </option>
        </b-select>
    </b-field>
</template>

<script>
    export default {
        name: "SelectPaymentType",
        props: {
            label: {
                type: String,
                default: 'Método de pago'
            },
            value: {},
            disabled: Boolean,
            optionValue: {
                type: String,
                default: 'code'
            },
            optionText: {
                type: String,
                default: 'description'
            },
            defaultOptions: {
                type: Array,
                required: true
            }
        },
    }
</script>

<style scoped>

</style>