<template>
  <div class="columns is-multiline is-gapless">
    <div class="column is-7-desktop is-8-widescreen is-9-fullhd has-background-white">
      <div style="padding: 1.25rem;" class="cancel_message">
        <b-message type="is-danger" v-if="data.is_cancel">
          Orden Cancelada
        </b-message>
        <form-order
            class="columns is-multiline"
            :data="data"
            :saved="saved"
            :read-only="readOnly"
            @setData="onSetData"
            :no-medical.sync="noMedical"
            @changeOrder="onGetOrder"
            @order-update-patient-sign="handleUpdatePatientSign"
            @update-medical="handleUpdateMedical"
            @update-dispenser-ticket="handleUpdateDispenserTicket"
        >
        </form-order>
      </div>
      <div class="column is-12">
        <order-box-observations :data="data"
                                :can-update="readOnly && $alfalab.allowEdit($auth.user(), data)">
        </order-box-observations>
      </div>
      <div class="column is-12">
        <pos-order-study :company="data.company"
                         :saved="saved"
                         :data="data"
                         :is-show="!!data.order_new"
                         @updateStudies="handleUpdateStudies"
                         @updateDeliveryDate="handleUpdateDeliveryDate"
                         :print-order="! canPrintOrder">
        </pos-order-study>
      </div>
    </div>
    <div class="column is-5-desktop is-4-widescreen is-3-fullhd has-background-white"
         style="border-left: 2px #dbdbdb solid">
      <div style="padding: 1.25rem;">
        <div class="order-summary">
          <template v-if="hasPatient">
            <div class="is-divider" data-content="Paciente"></div>
            <data-order-patient :data="patient"
                                :read-only="readOnly"
                                :allow-edit="$alfalab.allowEdit($auth.user(), data) || !data.order_new"
            ></data-order-patient>
          </template>
          <select-date label="Fecha de entrega"
                       style="margin-top: 1em"
                       v-model="data.delivery_date">
          </select-date>
          <b-button v-if="readOnly && $alfalab.allowEdit($auth.user(), data)"
                    @click="onUpdateOrder('updateDeliveryDate')"
                    class="button is-primary is-rounded">Actualizar Fecha de Entrega</b-button>
          <div class="is-size-6">
            <div class="field" style="margin-bottom: 1em">
              <div class="is-divider" data-content="Importe"></div>
              <div>
                <span class="is-pulled-left">Subtotal:</span>
                <span class="is-pulled-right loader" v-if="priceLoader"></span>
                <span v-else class="is-pulled-right">{{ subtotal | currency }}</span> <br>
                <span>
              <b-checkbox v-model="withIva" :disabled="readOnly">
                IVA:
              </b-checkbox>
              </span>
                <span class="is-pulled-right loader" v-if="priceLoader"></span>
                <span v-else class="is-pulled-right"><span v-if="withIva">{{ tax | currency }}</span><span v-else>{{0| currency}}</span></span> <br>
                <template v-if="totalDiscount > 0 || (coPay && company.copay === '?')">
                  <br v-if="coPay && company.copay === '?'">
                  <span class="is-pulled-left has-text-danger">
                  Descuento <span v-if="coPay">por copago</span>:
                </span>
                  <span class="is-pulled-right loader" v-if="priceLoader"></span>
                  <span v-else class="is-pulled-right has-text-danger">
                  <div v-if="coPay && company.copay === '?'" >
                    <b-field label="%">
                      <b-input
                          v-model="coPayCustomPercent"
                          :disabled="readOnly"
                          style="width: 100px"
                          type="number"
                          @input="checkOnlyPercents"
                      />
                    </b-field>
                  </div>
                  <span v-else>{{ totalDiscount | currency }}</span>
                </span>
                  <br v-if="coPay && company.copay === '?'">
                  <br>
                </template>
                <span class="is-pulled-left has-text-weight-bold">
                Total:
              </span>
                <span class="is-pulled-right loader" v-if="priceLoader"></span>
                <span v-else class="is-pulled-right has-text-weight-bold">
                {{ total | currency }}
              </span>
              </div>
              <br>
              <div>
                <b-message type="is-success" v-if="paid">
                  Pagado
                </b-message>
                <b-field>
                  <b-checkbox
                      v-if="(!data.sign && !readOnly) || (data.sign)"
                      :disabled="readOnly"
                      v-model="data.express"
                      false-value="0"
                      true-value="1">
                    Urgente
                  </b-checkbox>
                  <b-checkbox
                      v-else-if="!data.sign"
                      @input="onChangeUrgente"
                      v-model="data.express"
                      false-value="0"
                      true-value="1">
                    Urgente
                  </b-checkbox>
                </b-field>
              </div>
            </div>
          </div>
        </div>
        <div class="order-actions">
          <div class="is-divider" data-content="Acciones"></div>
          <div class="buttons">
            <button v-if="false" class="button is-rounded" type="button" @click="openIntegrationsModal">Integraciones</button>
            <button class="button is-rounded is-info" type="button" @click="(event) => openSearchModal(false)">
              <b-icon icon="search"></b-icon>
              <span>Buscar</span>
            </button>
            <button class="button is-rounded is-info" type="button" @click="(event) => openSearchModal(true)">
              <b-icon icon="qrcode"></b-icon>
              <span>QR</span>
            </button>
            <button class="button is-rounded" type="button" @click="onClearData">
              <b-icon icon="broom"></b-icon>
              <span>Limpiar</span>
            </button>
            <button class="button is-rounded"
                    :disabled="! canPrintOrder || $alfalab.disableForForeignWork(this, !!data.is_cancel)"
                    @click="modalPrint = true">
              <b-icon icon="print"></b-icon>
              <span>Imprimir</span>
            </button>

            <button class="button is-rounded"
                    :disabled="! canApplyDiscount"
                    @click="modalApplyDiscount = true">
              <b-icon icon="print"></b-icon>
              <span>Aplicar descuento</span>
            </button>
            <button class="button is-rounded"
                    :disabled="! canApplyPromotion"
                    v-if="$alfalab.userDevelopers(this)"
                    @click="modalApplyPromotion = true">
              <b-icon icon="tag"></b-icon>
              <span>Promociones especiales</span>
            </button>
            <button class="button is-rounded"
                    :disabled="! canPayOrder"
                    @click="activeModalPayment = true">
              <b-icon icon="money-bill-wave"></b-icon>
              <span v-if="readOnly">Ver Pago</span>
              <span v-else>{{ paid ? 'Editar Pago' : 'Agregar Pago' }}</span>
            </button>
            <button class="button is-primary is-rounded"
                    :disabled="! canCreateOrder"
                    type="button"
                    @click="onStoreData">
              <b-icon icon="save"></b-icon>
              <span>
                Guardar
              </span>
            </button>
            <OrderCancelButton
                :id="data.id"
                :disabled="!readOnly"
                v-if="data.can_cancel && $alfalab.canCancelOrders($auth.user())"/>
<!--            <button class="button is-secondary is-rounded"-->
<!--                    type="button"-->
<!--                    @click="runLapiValidations">-->
<!--              <b-icon icon="save"></b-icon>-->
<!--              <span>-->
<!--                Probar validaciones-->
<!--              </span>-->
<!--            </button>-->
          </div>
            <b-field grouped group-multiline>
              <p class="control">
                <b-modal :active.sync="activeModalPayment"
                         has-modal-card
                         trap-focus
                         :destroy-on-hide="true"
                         aria-role="dialog"
                         aria-modal>
                  <payment-modal-order
                      :read-only="readOnly"
                      :folio.sync="data.note"
                      :total="total"
                      :subtotal="subtotal"
                      :tax="tax"
                      :data="data"
                      @update-payments="onUpdateOrder('updatePayments', () => {activeModalPayment = false})"
                  ></payment-modal-order>
                </b-modal>
              </p>
            </b-field>
        </div>
      </div>
    </div>
    <div class="column is-12">
    </div>
    <b-modal :active.sync="modalPrint" destroy-on-hide>
      <modal-print
          @close-print-modal="onCloseModalPrint"
          :data="data"
          :has-company="hasCompany" :company="company"></modal-print>
    </b-modal>
    <b-modal :active.sync="modalApplyDiscount" :destroy-on-hide="false">
      <modal-discount-card v-model="discount_card"></modal-discount-card>
    </b-modal>
    <b-modal :active.sync="modalApplyPromotion">
      <modal-promotion v-model="promotion"></modal-promotion>
    </b-modal>
    <b-modal
        :active.sync="modalConfirm"
        destroy-on-hide
        :can-cancel="true"
        :has-modal-card="true">
      <ModalConfirm
          title="Guardar Órden"
          cancel-text="No, Cancelar"
          confirm-text="Si, Guardar"
          :content="contentConfirm"
          :on-confirm="functionConfirm"
      />
    </b-modal>
    <b-modal v-model="showContentModal" has-modal-card
             trap-focus
             :destroy-on-hide="true"
             aria-role="dialog"
             aria-modal>
      <div class="modal-card" style="width: auto; min-width: 500px">
        <header class="modal-card-head">
          <p class="modal-card-title">Confirmar información</p>
          <button
              type="button"
              class="delete"
              @click="$parent.close()"/>
        </header>
        <section class="modal-card-body" v-if="showContentModal">
          <div class="content">
            <p><strong>Paciente:</strong> {{tmpData.patient.name}} {{tmpData.patient.last_name}} {{tmpData.patient.other_name}}</p>
            <p><strong>Nacimiento:</strong> {{tmpData.patient.birthday}}</p>
            <p v-if="tmpData.company"><strong>Empresa:</strong> {{tmpData.company.name}}</p>
            <h3>Estudios</h3>
            <table class="table">
              <thead>
              <tr>
                <th>Clave</th>
                <th>Nombre</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="tmpStudy in tmpData.studies">
                <td>{{tmpStudy.code}}</td>
                <td>{{tmpStudy.name}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button native-type="button" type="is-primary" @click="showContentModal = false">Aceptar</b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FormOrder from "../../components/order/FormOrder";
import DataOrderPatient from "../../components/patient/DataOrderPatient";
import SearchModalOrder from "../../components/order/SearchModalOrder";
import OrderPosSamples from "../../components/order/OrderPosSamples";
import ModalPrint from "../../components/print/ModalPrint";
import PosOrderStudy from "../../components/order/PosOrderStudy";
import PaymentModalOrder from "../../components/order/PaymentModalOrder";
import ModalDiscountCard from "../../components/discount/ModalDiscountCard";
import {mapState, mapGetters} from 'vuex';
import OrderBoxObservations from "@/components/order/OrderBoxObservations";
import SelectDate from "@/components/partials/SelectDate";
import OrderCancelButton from "@/components/order/OrderCancelButton";
import MarqueeText from "@/components/MarqueeText";
import ModalConfirm from "@/components/ModalConfirm";
import IntegrationModal from "@/components/integrations/IntegrationModal";
import ModalPromotion from "@/components/promotion/ModalPromotion";

window.JSPM = require('jsprintmanager');

export default {
  name: "PosOrder",
  components: {
    OrderCancelButton,
    SelectDate,
    OrderBoxObservations,
    ModalDiscountCard,
    PaymentModalOrder,
    PosOrderStudy,
    ModalPrint,
    OrderPosSamples,
    DataOrderPatient,
    FormOrder,
    MarqueeText,
    ModalConfirm,
    IntegrationModal,
    ModalPromotion,
    Keypress: () => import('vue-keypress')
  },
  data() {
    return {
      wrapper: null,
      modalPrint: false,
      data: {},
      activeTab: 'studies',
      apiUrl: 'orders',
      activeModalFolio: false,
      activeModalPayment: false,
      discount_lab: 0,
      discount_gab: 0,
      modalApplyDiscount: false,
      modalApplyPromotion: false,
      discount_card: null,
      promotion: null,
      noMedical: true,
      saved: {
        studies: false,
        company: false
      },
      onProcess: false,
      contentConfirm: '',
      functionConfirm: () => {},
      modalConfirm: false,
      tmpData: {},
      showContentModal: false
    }
  },
  computed: {
    ...mapState('order', [
      'studies', 'patient', 'company', 'medical', 'paid', 'localNumberKey', 'priceLoader', 'coPay', 'payInCash',
      'diagnostics', 'agreement', 'foreignWork', 'foreignWorkCheck', 'studies', 'discountLab', 'discountGab', 'medicalCode'
    ]),
    ...mapGetters('order', [
      'subtotal', 'tax', 'total', 'hasCompany', 'canPayOrder', 'canCreateOrder', 'hasPatient', 'canPrintOrder',
      'readOnly', 'totalDiscount', 'totalDiscountGab', 'totalDiscountLab', 'totalLab', 'totalGab', 'canApplyDiscount',
      'percentCopay', 'isCancel', 'canApplyPromotion', 'hasDataVaccineCovid'
    ]),
    ...mapState('order/orderPayments', [
      'payments'
    ]),
    showDocumentsModal: {
      get() {
        return this.$store.state.utils.showDocumentsModal;
      },
      set(val) {
        this.$store.dispatch('setShowDocumentsModal', val);
      }
    },
    minDateDelivery() {
      return new Date(this.$moment().add(this.days, 'days').format());
    },
    coPayCustomPercent: {
      get () {
        return this.$store.state.order.coPayCustomPercent;
      },
      set (value) {
        this.$store.dispatch('order/setCoPayCustomPercent', value);
      }
    },
    withIva: {
      get(){
        return this.$store.state.order.withIva;
      },
      set(value) {
        this.$store.dispatch('order/setWithIva', value);
      }
    }
  },
  created() {
    this.prepareData();
  },
  mounted() {
    this.wrapper = document.getElementById('main-wrapper');
    this.wrapper.classList.add('is-expanded');
    this.$on('searchOrder', async data => {
     // console.log('comenzando a limpiar');
      await this.onClearData(null, true);
     // console.log('comenzando a asignar');
      await this.onSetData(data);
    });
    this.$on('showMedicalPassInfo', data => {
      this.showContentModal = true;
      this.tmpData = data;
    });
    const vm = this;
    const printManager = localStorage.getItem('printerManager') || this.$lapi_printer_managers.jsprint;
    if(printManager === this.$lapi_printer_managers.jsprint){
      JSPM.JSPrintManager.auto_reconnect = true;
      JSPM.JSPrintManager.start();

      JSPM.JSPrintManager.WS.onStatusChanged = function () {
        //vm.canPrint = true;
      };
    }
  },
  destroyed() {
    this.wrapper.classList.remove('is-expanded');
  },
  methods: {
    someMethod() {
      alert('ok');
    },
    setData(data) {
      console.log(data, 'set data');
    },
    async prepareData(loader = true) {
      if(loader){
        this.$loader.show();
      }

      const hasCompanyLock = this.$alfalab.hasCompanyLock(this);

      if(hasCompanyLock.status){
        this.saved = Object.assign({}, this.saved, {company: true});
        if(!this.data.company){
          const lockCompany = await this.$alfalab.getCompany(this, hasCompanyLock.code);
          this.data = Object.assign({}, this.data, {company: lockCompany});
          await this.$store.dispatch('order/setCompany', lockCompany);
        }
      }
      this.data = {
        no_local: this.localNumberKey,
        medical: {},
        date: new Date(),
        company: this.saved.company ? this.data.company : {pay_in_cash: 'all'},
        patient: {},
        branch_office: this.$auth.user().active_branch_office,
        delivery_date: new Date(this.$moment().add(2, 'days')),
        order_new: null,
        express: '0',
        studies: this.saved.studies ? this.data.studies : [],
        payments: [],
        type: '',
        location: '',
        extra6: '', //hora de toma de muestra
        pass: '', //pase / referencia
        location_number: ''
      };
      this.noMedical = true;
      await this.$store.dispatch('order/clearOrder', this.saved);
      await this.$store.dispatch('order/orderPayments/clearPayments');
      await this.$store.dispatch('order/setDefaultMedical');

      if(this.$auth.user().sub_branch_office){
        await this.$store.dispatch('order/setSubBranchOffice', this.$auth.user().sub_branch_office.id);
        this.data['sub_branch_office'] = this.$auth.user().sub_branch_office.id;
      }

      if(loader){
        setTimeout(() => {
          if(loader){
            this.$loader.hide();
            this.$alfalab.focus(this.$auth.user())
          }
        }, 500);
      }
     // console.log('terminando de limpiar');
    },
    showDataForPrint(data) {
      this.data = {
        ...data,
        company: data.company || {},
        medical: data.medical || {}
      }
    },
    showTab(tab) {
      this.activeTab = tab;
    },
    async onStoreData() {
      if (!this.runLapiValidations()) {
        return;
      }
      const warnings = this.runLapiWarnings();
      const extras = this.$alfalab.confirmExtraData(this.$auth.user(), this.data);
      this.contentConfirm = '¿Todos los datos son correctos?' + warnings + extras;
      this.modalConfirm = true;
      this.functionConfirm = () => {
        if(this.onProcess){
          return;
        }
        this.onProcess = true;
        this.$loader.show();
        let orderData = {
          ...this.data,
          no_iva: !this.withIva,
          company: this.company,
          studies: this.studies,
          payments: this.payments,
          patient: this.patient,
          medical: this.medical,
          hasCopay: this.coPay,
          agreement: this.agreement,
          percentCopay: this.company.copay && this.company.copay === '?' ? this.coPayCustomPercent:  this.percentCopay,
          discount_gab: this.totalDiscountGab,
          discount_lab: this.totalDiscountLab,
          total_lab: this.totalLab,
          total_gab: this.totalGab,
          payInCash: this.payInCash,
          diagnostics: this.diagnostics,
          foreignWork: this.foreignWork,
          foreignWorkCheck: this.foreignWorkCheck,
          medicalCode: this.medicalCode,
          patient_sign: this.$store.state.order.patientSign,
          no_receive: this.$store.state.order.noRecieve,
          alternative_names: this.$store.state.order.alternativeNames
        }

        if (!!this.discount_card) {
          orderData.discount_card = this.discount_card
        }

        orderData.ui_version = this.$alfalab.version();

        this.storeData(orderData).then(({data}) => {
          this.$loader.hide();
          this.onSetData(data);
          this.showDataForPrint(data);
          this.modalPrint = true;
        }).catch(errors => {
          this.$alfalab.showError(errors);
        }).finally(() => {
          this.$loader.hide();
          this.modalConfirm = false;
          this.onProcess = false;
        });
      }
    },
    async onClearData(callback, withoutConfirm = false ) {
      if (withoutConfirm || this.$alfalab.isFastMode(this.$auth.user())) {
        await this.prepareData();
      } else {
        this.$buefy.dialog.confirm({
          title: 'Limpiar datos de captura',
          message: '¿Estas seguro de limpiar los datos de captura?',
          confirmText: 'Limpiar datos.',
          cancelText: 'No, cancelar.',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            const user = this.$auth.user();
            if(false && user && this.$alfalab.enableForeignWorkUI(user)){
              window.location.reload();
              return;
            }
            await this.prepareData();
            if (callback && typeof callback === 'function') {
              callback();
            }
          }
        })
      }
    },
    onGetOrder() {
      this.getData()
    },
    async onSetData(data) {
      await this.onClearData(null, true);
      await this.prepareData(false);
      let branchOffice = data.branch_office ? data.branch_office : this.$auth.user().active_branch_office;
      this.data = {
        ...data,
        branch_office: branchOffice,
        sub_branch_office: this.$store.state.order.subBranchOffice !== 0 ? this.$store.state.order.subBranchOffice : '',
        date: new Date(data.date || new Date()),
        delivery_date: new Date(data.delivery_date || new Date()),
      }
      if(data.diagnostics){
        for(let i=0;i<data.diagnostics.length;i++){
          if(!data.diagnostics[i]){
            data.diagnostics[i] = {};
          }
        }
      }

      await this.$store.dispatch('order/setMedical', data.medical);
      await this.$store.dispatch('order/setPatient', data.patient);
      await this.$store.dispatch('order/setStudies', data.studies);
      await this.$store.dispatch('order/setCompany', Array.isArray(data.company) ? {} : data.company);
      await this.$store.dispatch('order/setDiagnostics', data.diagnostics || []);
      if(data.no_iva){
        await this.$store.dispatch('order/setWithIva', !data.no_iva);
      }
      if(data.payment){
        await this.$store.dispatch('order/orderPayments/addPayment', data.payment);
        await this.$store.dispatch('order/payOrder', true);
      }

      //cargamos el descuento seleccinado en la cotización
      if(data.discountCard && (data.discountCard.desc_gab || data.discountCard.desc_lab)){
        await this.$store.dispatch('order/applyDiscount', data.discountCard);
        this.discount_card = data.discountCard.id;
      }

      if(data.foreignWork){
          const foreignWorks = this.$store.state.main.foreignWorks;
          let foreignWork = this.$store.state.main.foreignWorks.find(item => {return item.id === data.foreignWork.id})
          try{
              if(!foreignWork){
                  foreignWorks.push({
                      id: data.foreignWork.id,
                      name: data.foreignWork.name
                  });
                  await this.$store.dispatch('setForeignWorks', foreignWorks.slice(0));
              }
              this.$store.state.order.foreignWork = data.foreignWork.id;
              this.$store.state.order.foreignWorkName = foreignWorks.find(item => {return item.id === data.foreignWork.id}).name || ''
          }catch (e){}
      }

      if (!!data.order_new) {
        this.$store.state.order.payInCash = data.pay_in_cash;
        this.$store.state.order.coPay = data.coPay;
        if(data.copay_percent){
          this.$store.state.order.coPayCustomPercent = data.copay_percent;
        }
        this.$store.state.order.agreement = data.agreement;
        this.$store.state.order.foreignWorkCheck = !!data.foreignWorkCheck;
        await this.$store.dispatch('order/setReadOnly', !!data.order_new);
        await this.$store.dispatch('order/setLocalNumber', data.no_local++);
        let payments = data.payments || [];
        if (payments.length) {
         // console.log('cargando pagos', payments);
          await this.$store.dispatch('order/orderPayments/setPayments', payments);
        }
        const {desc_lab, desc_gab} = data;
        if (desc_lab || desc_gab) {
          await this.$store.dispatch('order/applyDiscount', data);
        }
        await this.$store.dispatch('order/setAlternativeNamesData', data.alternative_names);
      }
      if(data.autoSave){
        setTimeout(() => {
          this.onStoreData();
        }, 200);
      }
   //   console.log('terminando de asignar');
    },
    openSearchModal(onlyQr = false) {
      this.$buefy.modal.open({
        parent: this,
        component: SearchModalOrder,
        hasModalCard: true,
        // customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          onlyQr: onlyQr
        }
      });
    },
    openIntegrationsModal() {
      this.onClearData(() => {
        this.$buefy.modal.open({
          parent: this,
          component: IntegrationModal,
          hasModalCard: true,
          trapFocus: true
        });
      });

    },
    onPrint(order = null) {
      const windowOpen = window.open(`${process.env.VUE_APP_URL}/print/order/${order}`, '', 'status=1,width=800,height=800');
      // windowOpen.document.close(); //missing code
      windowOpen.focus();
      windowOpen.print();
      // window.open(`${process.env.VUE_APP_URL}/print/order/${this.data.order_new}`);
    },
    onPrintLabels(order = null) {
      let sURL = `${process.env.VUE_APP_URL}/print/order/${order}?type=labels`;
      const windowOpen = window.open(sURL, '', 'status=1,width=800,height=800');
      windowOpen.focus();
      windowOpen.print();
    },
    runLapiValidations() {
      let result = true;
      let message = '';
      const order_validations = this.$auth.user().data_branch_office.extra_settings.filter(item => {return item.type === 'order.validation'});
      for(const validation of order_validations){
        const status = this.$lapi_validations[validation.action].call(this, Object.assign({}, this.data));
        if(!status.status){
          result = false;
          message+=status.message + "\n"
        }
      }

      if(this.company && this.company.extra_settings && Array.isArray(this.company.extra_settings)){
        const company_validations = this.company.extra_settings.filter(item => {return item.type === 'company.validation'});
        for(const validation of company_validations){
          const status = this.$lapi_validations[validation.action].call(this, Object.assign({}, {data: this.data,
            patient: this.patient,
            medical: this.medical,
            diagnostics: this.diagnostics,
            company: this.company,
            medicalCode:  this.medicalCode
          }));
          if(!status.status){
            result = false;
            message+=status.message + "\n"
          }
        }
      }
      if(this.hasDataVaccineCovid){
        if(!!!this.data.extra_1){
          message+="El No. Lote es requerido para vacunas COVID, puedes agregarlos en los campos extra\n";
          result = false;
        }
      }
      if(!result){
        this.$buefy.dialog.alert({
          title: 'Se presentaron los siguientes errores en la validación',
          message: message.toString().replaceAll("\n", "<br />")
        });

      }
      return result;
    },
    runLapiWarnings(){
      let result = '<ul style="list-style: initial;">';
      let acc = 0;
      const order_warnings = this.$auth.user().data_branch_office.extra_settings.filter(item => {return item.type === 'order.warning'});
      for(const warning of order_warnings){
        const warning_text = this.$lapi_warnings[warning.action].call(this, Object.assign({}, this.data));
        result += '<li>' + warning_text + '</li>';
        if(warning_text){
          acc++;
        }
      }
      if(this.$auth.user().branch_can_ask_patient_sign){
        result += '<li>No olvide capturar la firma para aviso de privacidad</li>';
        acc++;
      }
      if ( acc >  0){
        return result + "</ul>";
      }
      return '';
    },
    onUpdateOrder(action, callback){
      this.$loader.show();
      const data = {
        ...this.data,
        company: this.company,
        studies: this.studies,
        payments: this.payments,
        patient: this.patient,
        medical: this.medical,
        hasCopay: this.coPay,
        percentCopay: this.percentCopay,
        discount_gab: this.totalDiscountGab,
        discount_lab: this.totalDiscountLab,
        total_lab: this.totalLab,
        total_gab: this.totalGab,
        payInCash: this.payInCash,
        diagnostics: this.diagnostics,
        action: action
      }
      this.$http.put(`order-update/${this.data.id}`, data).then(({data}) => {
        this.$loader.hide();
        this.$toast.success(data.message);
      }).catch(error => {
        this.$alfalab.showError(error);
      }).finally(() => {
        this.$loader.hide();
        if(callback){
          callback();
        }
      });
    },
    handleUpdateMedical() {
      console.log('update-medical-chato');
      this.$loader.show();
      const data = {
        medical: this.medical,
        company: this.company,
        medicalCode: this.medicalCode,
        action: 'updateMedical'
      }
      this.$http.put(`order-update/${this.data.id}`, data).then(({data}) => {
        this.$loader.hide();
        this.$toast.success(data.message);
      }).catch(error => {
        this.$alfalab.showError(error);
      }).finally(() => {
        this.$loader.hide();
      });
    },
    handleUpdatePatientSign() {
      console.log('event');
      this.$loader.show();
      const data = {
        action: 'updateSign',
        patient_sign: this.$store.state.order.patientSign
      }
      this.$http.put(`order-update/${this.data.id}`, data).then(({data}) => {
        this.$loader.hide();
        this.$toast.success(data.message);
      }).catch(error => {
        this.$alfalab.showError(error);
      }).finally(() => {
        this.$loader.hide();
      });
    },
    handleUpdateDispenserTicket() {
      const data = {
        action: 'updateDispenserTicket',
        dispenser_ticket: this.data.dispenser_ticket
      }
      this.$loader.show();
      this.$http.put(`order-update/${this.data.id}`, data).then(({data}) => {
        this.$loader.hide();
        this.$toast.success(data.message);
      }).catch(error => {
        this.$alfalab.showError(error);
      }).finally(() => {
        this.$loader.hide();
      });
    },
    checkOnlyPercents(value) {
      if(Number.isNaN(value) || value <0 || value > 100){
        this.coPayCustomPercent = 0;
      }
    },
    onCloseModalPrint(isClose){
      this.modalPrint = false;
      setTimeout(() => {
        this.onClearData();
      }, 250)
    },
    onChangeUrgente(value) {
      this.$buefy.dialog.confirm({
        title: 'Actualizar',
        message: '¿Estas seguro de actualizar la orden?',
        confirmText: 'Si, actualizar',
        cancelText: 'No, cancelar',
        onConfirm: () => {
          this.onUpdateOrder('updateUrgent');
        },
        onCancel: () => {
          if(value == 1) {
            this.data.express=0;
          } else {
            this.data.express=1;
          }
        }
      });
    },
    handleUpdateStudies(data) {
      let maxDays = data.reduce((acc, item) => {
        return acc > item.delivery_days ? acc : item.delivery_days;
      }, 0)
      maxDays = maxDays === 0 ? 1 : maxDays;
      const delivery_date = new Date(this.$moment().add(maxDays, 'days'));
      this.data = {...this.data, delivery_date}
    },
    runPatientSignProcess() {

    },
    handleUpdateDeliveryDate(delivery_date) {
      this.data = {...this.data, delivery_date}
    }
    /*onPrintLabels() {
      let sURL = `${process.env.VUE_APP_URL}/print/order/${this.data.order_new}?type=labels`;
      const windowOpen = window.open(sURL, '','status=1,width=800,height=800');
      windowOpen.focus();
      windowOpen.print();
      // window.open(`${process.env.VUE_APP_URL}/print/order/${this.data.order_new}`);
    },*/
  },
  async beforeDestroy() {
   // console.log('Limpando POS');
    await this.prepareData(true);
  },
}
</script>

<style scoped>
.order-summary {
  /*max-height: 60vh;*/
  /*overflow: auto;*/
}
</style>
