<template>
  <b-field :label="label">
    <b-input
            :id="`searchorder_${type}`"
             ref="order_new"
             :disabled="disabled"
             :readonly="readOnly"
             :value="value"
             @input="val => $emit('input', val)"
             @keypress.native.enter="onSearch"
             @blur="onSearch2"
             autofocus>
    </b-input>
    <b-modal v-model="showModalArd57Nov23" has-modal-card
             trap-focus
             :destroy-on-hide="true"
             aria-role="dialog"
             aria-modal>
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Confirmar información</p>
          <button
              type="button"
              class="delete"
              @click="$parent.close()"/>
        </header>
        <section class="modal-card-body" v-if="tmpData.id">
          <div class="content">
            <p><strong>Paciente:</strong> {{tmpData.patient.full_name}}</p>
            <p><strong>No Empleado:</strong> {{tmpData.patient.employee}}</p>
            <p><strong>Nacimiento:</strong> {{tmpData.patient.birthday}}</p>
            <br>
            <b-field label="Hizo ayuno">
              <b-select v-model="tmpData.observations">
                <option value="Ayuno: Si">Si</option>
                <option value="Ayuno: No">No</option>
              </b-select>
              <br><br>
            </b-field>
            <b-field label="Acepto aviso de privacidad">
              <b-checkbox v-model="tmpData.aviso_privacidad" />
            </b-field>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button native-type="button" type="is-danger" @click="showModalArd57Nov23 = false">Cancelar</b-button>
          <b-button native-type="button" type="is-default"
                    :disabled="!!!tmpData.aviso_privacidad"
                    @click="setArd57Nov23">Son Correctos</b-button>
        </footer>
      </div>
    </b-modal>
  </b-field>
</template>

<script>
import ModalSelectOrder from "@/components/order/ModalSelectOrder";

export default {
  name: "SearchOrder",
  props: {
    label: {
      type: String,
      default: 'Orden General'
    },
    placeholder: {
      type: String,
      default: 'No. Gral.'
    },
    disabled: Boolean,
    readOnly: Boolean,
    value: {},
    type: String,
    year: {
      type: String,
      default() {
        return this.$store.state.main.year
      }
    },
    withLapiLabels: {
      type: Boolean,
      default: false
    },
    downloadResultsLink: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      apiUrl: 'orders',
      text: '',
      params: {
        local: null,
        branch_office: this.$auth.user().active_branch_office
      },
      in_progress: 0,
      showModalArd57Nov23: false,
      tmpData: {}
    }
  },
  computed: {
    years() {
      return this.$store.state.main.years || [];
    }
  },
  mounted() {
    //console.log(this.$alfalab.isFastMode(this.$auth.user()) + ' ' + this.$alfalab.enableForeignWorkUI(this.$auth.user())  + " " + this.type)  ;
    if(this.$alfalab.isFastMode(this.$auth.user())){
      if(this.$alfalab.enableForeignWorkUI(this.$auth.user()) && this.type === 'preorder'){
          this.$refs.order_new.focus();
      }else if(!this.$alfalab.enableForeignWorkUI(this.$auth.user()) && this.type === 'order'){
        console.log('colocando a order')
      }
    }else if(this.type === 'medical_pass'){
      this.$refs.order_new.focus();
    }
  },
  methods: {
    async onSearch() {
      if(this.in_progress === 0){
        this.in_progress = window.performance.now();
       // console.log(this.in_progress);
        if(!this.type || !this.value){
          return;
        }
        this.$loader.show();
        switch (this.type){
          case 'order':
            await this.searchOrder();
            break;
          case 'local':
            this.params.local = this.value;
            await this.searchOrderWithParams();
            break;
          case 'preorder':
            this.params.preorder = this.value;
            await this.searchPreOrder();
            break;
          case 'folio':
            this.params.folio = this.value;
            await this.searchOrderFolio();
            break;
          case 'minas-2022':
          case 'minas-2024':
            this.params.employer = this.value;
            await this.searchMinasByEmployer();
            break;
          case 'ard57-nov2023':
            this.params.employer = this.value;
            await this.searchARD57Nov23ByEmployer();
            break;
          default:
            await this.searchFolio();
            break;
        }
        this.$loader.hide();
        this.in_progress = 0;
      }
    },
    searchOrderWithParams() {
      this.apiUrl = 'orders';
      this.params = Object.assign({}, this.params, {withLapiLabels: this.withLapiLabels});
      return this.getAll(this.params).then(({data}) => {
        if (data.length) {
          this.$parent.$parent.$emit('searchOrder', data[0]);
          this.$emit('setData', data[0]);
        }
      }, error => {
        this.$toast.error('Sin resultados');
      });
    },
    searchOrder() {
      this.order = String(this.year) + this.value.padStart(7);
      this.apiUrl = 'orders';
      if(this.order){
        return this.getData(this.order, {
          withLapiLabels: this.withLapiLabels,
          downloadResultsLink: this.downloadResultsLink
        }).then(({data = {}}) => {
          this.$parent.$parent.$emit('searchOrder', data);
          this.$emit('setData', data);
        }, data => {
          this.$toast.error(data.message);
        });
      }else{
        this.$toast.error("Se debe especificar la orden");
      }
    },
    getOrder(ordernew) {
      this.apiUrl = 'orders';
      return this.getData(ordernew, {withLapiLabels: this.withLapiLabels}).then(({data = {}}) => {
        this.$parent.$parent.$emit('searchOrder', data);
        this.$emit('setData', data);
      }, data => {
        this.$toast.error(data.message);
      });
    },
    searchFolio() {
      this.apiUrl = this.type === 'medical_pass' ? 'medical-pass' : 'medical-pass-imss';
      return this.getData(this.value).then(({data = {}}) => {
        this.$parent.$parent.$emit('searchOrder', data);
        this.$emit('setData', data);
      }, data => {
        this.$toast.error(data.message);
      });
    },
    async searchPreOrder() {
      let searchable = this.value;
      this.apiUrl = 'folio/quotation';
      if(this.value.toString().length === 9){
        await this.getAll({barcode: this.value}).then(({data}) => {
          searchable = data[0].quotation_id ?? '';
        }).catch(() => {
          this.$loader.hide();
        }).finally(() => {
        })
      }
      return this.getData(searchable, {withOrder:true}).then(({data = {}}) => {
        const preorderData = {preorder: data.quotation_id};
        if(this.$alfalab.isFastMode(this.$auth.user())){
          preorderData['autoSave'] = true;
        }
        if(this.$alfalab.hasObsAsExtra1(this.$auth.user())) {
          preorderData['extra_1'] = data.observations || '';
        }
        let result = Object.assign({}, data, preorderData);
        if(data.order){
          this.$buefy.dialog.alert({
            message: "Ya existe una orden asignada al número de pre orden solicitado<br>Mostrando información"
          })
          result = Object.assign({}, data, data.order,{preorder: data.quotation_id} )
        }
        this.$parent.$parent.$emit('searchOrder', result);
        this.$emit('setData', data);
      }).catch(errors => {
        this.$alfalab.showError(errors);
      })
    },
    async searchOrderFolio(){
      this.$loader.show();
      const vm = this;
      await this.$http.get('orders', {
        params: {
          folio: this.params.folio,
          withLapiLabels: this.withLapiLabels,
          "current-year": true,
          perPage: 200
        }
      }).then(({data}) => {
        this.$buefy.modal.open({
          parent: this,
          component: ModalSelectOrder,
          hasModalCard: true,
          customClass: 'custom-class custom-class-2',
          trapFocus: true,
          events: {
            selectOrder(data) {
              vm.getOrder(data.order_new);
            },
          },
          props: {
            data: data.data,
            showFolio: true
          }
        })
      }, ({response}) => {

      });
      this.$loader.hide();
    },
    searchMinasByEmployer(){
      //console.log('buscnado');
      this.apiUrl = 'foreign-work';
      if(this.params.employer){
        return this.getData(this.params.employer, {
          withLapiLabels: this.withLapiLabels,
          downloadResultsLink: this.downloadResultsLink
        }).then(({data = {}}) => {
          this.$parent.$parent.$emit('searchOrder', data);
          this.$emit('setData', data);
        }, data => {
          this.$toast.error(data.message);
        });
      }else{
        this.$toast.error("Se debe especificar el número de empleado");
      }
    },
    searchARD57Nov23ByEmployer(){
      console.log('buscnado');
      this.apiUrl = 'quotation';
      if(this.params.employer){
        return this.getData(this.params.employer, {
          withLapiLabels: this.withLapiLabels,
          downloadResultsLink: this.downloadResultsLink,
          foreign: 'ard57-nov2023'
        }).then(({data = {}}) => {

          this.$parent.$parent.$emit('searchOrder', data);
          this.$emit('setData', data);

         // data.observations = 'Ayuno: Si';
         // this.tmpData = data;
        //  this.showModalArd57Nov23 = true;
        }, data => {
          this.tmpData = {};
          this.$toast.error(data.message);
        });
      }else{
        this.$toast.error("Se debe especificar el número de empleado");
      }
    },
    onSearch2() {
      if(this.$alfalab.mobileAndTabletCheck()){
        this.onSearch();
      }
    },
    setArd57Nov23(){
      this.showModalArd57Nov23 = false;
      this.$parent.$parent.$emit('searchOrder', this.tmpData);
      this.$emit('setData', this.tmpData);
      this.tmpData = {};
    }
  }
}
</script>

<style scoped>

</style>
