<template>
    <div class="box">
        <form autocomplete="off">
            <form-order :data="data">
            </form-order>
        </form>
    </div>
</template>

<script>
    import FormOrder from "../../components/order/FormOrder";
    export default {
        name: "Create",
        components: {FormOrder},
        data() {
            return {
                data: {}
            }
        },
        methods: {
            onSaveData() {

            }
        }
    }
</script>

<style scoped>

</style>