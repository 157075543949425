<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{title}}</p>
    </header>
    <section class="modal-card-body">
      <div class="media">
        <div class="media-left"><span class="icon has-text-danger is-large"><i class="fas fa-exclamation-circle fa-2x"></i></span></div>
        <div class="media-content">
          <p><span v-html="content"/></p>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <form @submit.prevent="onConfirm">
        <button class="button" type="button" @click="$parent.close()">
          {{cancelText}}
        </button>
        <button class="button is-primary"
                type="submit">
          {{confirmText}}
        </button>
      </form>
    </footer>
  </div>
</template>

<script>
export default {
  name: "ModalConfirm",
  props: {
    title: String,
    content: String,
    cancelText: String,
    confirmText: String,
    onConfirm: Function
  }
}
</script>

<style scoped>

</style>
