<template>
  <div>
    <b-tooltip label="Cancelar Orden">
      <button class="button is-danger is-rounded"
              type="button"
              @click="showModal = true"
              :disabled="disabled">
        <b-icon icon="times"></b-icon>
        <span>Cancelar</span>
      </button>
    </b-tooltip>


    <b-modal v-model="showModal" :can-cancel="false" width="640px">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Cancelar orden</p>
          <button
              type="button"
              class="delete"
              @click="showModal = false"/>
        </header>
        <section class="modal-card-body is-flex">
          <div class="media">
            <div class="media-left"><span class="icon has-text-danger is-large"><i
                class="fas fa-exclamation-circle fa-2x"></i></span></div>
            <div class="media-content">
              <p>
                ¿Estas seguro que deseas cancelar está orden?
              </p>
              <br>
              <b-field label="Motivo de cancelación">
                <b-select required expanded v-model="motive">
                  <option value="">Seleccionar...</option>
                  <option value="Cliente solicitó cancelación">Cliente solicitó cancelación</option>
                  <option value="Problemas de pago">Problemas de pago</option>
                  <option value="Incapacidad para cumplir con la entrega">Incapacidad para cumplir con la entrega</option>
                  <option value="Datos incorrectos">Datos incorrectos</option>
                  <option value="Error de sistema">Error de Sistema</option>
                  <option v-if="$alfalab.isAdmin($auth.user())" value="Cancelación por administrador">Cancelación por administrador</option>
                </b-select>
              </b-field>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot is-flex is-justify-content-end">
          <button type="button" class="button" @click="showModal = false"><span>No, cancelar</span></button>
          <button type="button"
                  @click="onCancel"
                  :disabled="motive === ''"
                  class="button is-danger"><span>Si, cancelar orden</span></button>
        </footer>
      </div>
    </b-modal>

  </div>
</template>

<script>
export default {
  name: "OrderCancelButton",
  props: {
    disabled: Boolean,
    id: String|Number,
  },
  data() {
    return {
      showModal: false,
      motive: ''
    }
  },
  methods: {
    async onCancel() {

      this.$loader.show();
      await this.cancelOrder(this.id)
          .then(({data}) => {
            this.$toast.success(data.message);
            this.$emit('canceledOrder', data.data || {});
          })
          .catch(errors => {
            this.$alfalab.showError(errors);
          }).finally(() => {
            this.$loader.hide();
            this.showModal=false;
            this.motive = '';
          });
    },
    cancelOrder(order) {
      return this.$http.delete(`orders/${order}?motive=${this.motive}`);
    }
  }
}
</script>

<style scoped>

</style>
