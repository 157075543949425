<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                Pase Médico IMSS
            </p>
        </header>
        <section class="modal-card-body">
            <b-field label="Buscar Folio" label-position="on-border" grouped>
                <b-input placeholder="Ej. 0001" v-model="folio" @keyup.enter.native="onSearch"></b-input>
                <p class="control">
                    <b-button class="button is-primary" type="button" @click="onSearch">
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </p>
            </b-field>
            <div class="is-divider" data-content="Paciente"></div>
            <form-patient-folio-imss
                    class="columns is-multiline"
                :data="data.patient"
            ></form-patient-folio-imss>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">Cerrar</button>
            <button class="button is-primary" @click="onSetData">Guardar</button>
        </footer>
    </div>
</template>

<script>
    import FormPatientFolioImss from "./FormPatientFolioImss";
    export default {
        name: "FormModalFolioImss",
        components: {FormPatientFolioImss},
    data() {
        return {
            apiUrl: 'folio/imss',
            folio: '',
            data: {}
        }
    },
    created() {
        this.prepareData();
    },
    methods: {
        prepareData() {
            this.data = {
                company: {},
                patient: {},
            };
        },
        onSearch() {
            this.$loader.show();
            this.getData(this.folio).then(data => {
                this.$loader.hide();
                this.data = data.data || {};
            }, data => {
                this.$buefy.dialog.alert({
                    title: '¡Ups!',
                    message: data.message,
                    type: 'is-danger',
                    hasIcon: true,
                });
                this.$loader.hide();
            })
        },
        onSetData() {
            this.$emit('setData', this.data);
            this.$parent.close();
        }
    }
    }
</script>

<style scoped>

</style>