<template>
  <div>
    <b-field label="Require resultados en físico">
      <b-checkbox v-model="data.requirePhysicalResults"/>
    </b-field>
    <b-field v-if="data.requirePhysicalResults" label="Observaciones de resultados en físico">
      <b-input type="textarea"
               v-model="data.physicalResults"
      />
    </b-field>
    <b-field v-if="canUpdate">
      <button class="button is-primary is-rounded"
              type="button"
              @click="$emit('updatePhysicalResults')">
        Actualizar Resultados en físico
      </button>
    </b-field>
  </div>
</template>

<script>
  export default {
    name: "OrderPhysicalResults",
    props: {
      data: Object,
      canUpdate: Boolean
    }
  }
</script>
<style scoped>

</style>