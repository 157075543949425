<template>
<div class="table-container lapi__log">
  <table class="table is-bordered is-striped is-narrow is-hoverable">
    <thead>
    <tr>
      <th>Fecha</th>
      <th>Modulo</th>
      <th>Referencia</th>
      <th>Mensaje</th>
      <th>Trama</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in data">
      <td>{{item.date}}</td>
      <td>{{item.module}}</td>
      <td>{{item.reference}}</td>
      <td>{{item.message}}</td>
      <td><textarea cols="30" rows="3">{{item.data}}</textarea></td>
    </tr>
    </tbody>
  </table>
</div>
</template>

<script>
export default {
  name: "OrderHistoryWeb",
  props: {
    order: {}
  },
  data() {
    return {
      data: [],
      apiUrl: 'lapi-log-web'
    }
  },
  mounted() {
    if(this.order.id){
      this.prepareData();
    }
  },
  methods: {
    prepareData() {
      this.getAll({
        order: this.order.order_new
      }).then(({data}) => {
        this.data = data;
      }).catch(() => {

      }).finally(() => {

      })
    }
  }
}
</script>

<style scoped>
.lapi__log{
  height: 300px;
  overflow: auto;
}
</style>
