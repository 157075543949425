<template>
<div class="modal-card" style="width: auto">
  <header class="modal-card-head">
      <p class="modal-card-title">Aplicar Promoción Especial.</p>
      <div style="text-align: center">
      <button
          type="button"
          class="delete"
          @click="$parent.close()"/>
      </div>
  </header>
  <section class="modal-card-body">
    <div class="columns is-multiline">
      <div class="column is-6">
        <span><b>Descuento por promoción especial: $100</b></span>
      </div>
      <div class="column is-6">
        <span>Total a pagar sin promoción: ${{ totalLab + totalGab }}</span>
      </div>
      <div class="column is-6">
        <b-field label="Usuario que autoriza"
                 label-position="on-border">
          <b-input v-model="user"></b-input>
        </b-field>
      </div>
      <div class="column is-6">
        <span v-model="totalPromotion"><b>Total a pagar aplicando promoción: ${{ totalPromotion= totalLab +  totalGab -100 }}</b></span>
      </div>
      <div class="column is-6">
        <b-field label="Contraseña"
                 label-position="on-border">
          <b-input v-model="password"></b-input>
        </b-field>
      </div>
    </div>
  </section>
  <footer class="modal-card-foot">
    <button class="button is-primary" type="button" @click="$parent.close()">
      Aplicar promoción y Cerrar
    </button>
    <button class="button is-danger" type="button" @click="$parent.close()">
      Cancelar
    </button>
  </footer>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ModalPromotion",
  data() {
    return{
      user: '',
      password: '',
      totalPromotion: '',
    }
  },
  computed: {
    ...mapGetters('order', [
        'totalLab','totalGab'
    ])
  }
}
</script>

<style scoped>

</style>