<template>
    <canvas id="myChart" height="300"></canvas>
</template>

<script>
    const Chart = require('chart.js');
    export default {
        name: "OrderStatsGraph",
        props: {
            data: Object
        },
        data() {
            return {
                chart: null
            }
        },
        mounted() {
            let ctx = document.getElementById('myChart');
            const {labels, data_set} = this.data;
            let data = {
                labels,
                datasets: data_set
            }
            console.log(data);
            this.chart = new Chart(ctx, {
                type: 'line',
                data: data,
                //options: options
            });
        }
    }
</script>

<style scoped>

</style>