<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Integraciones</p>
    </header>
    <section class="modal-card-body">
      <button class="button is-info" @click="onRunWeeCompany">WeeCompany</button>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Cerrar</button>
    </footer>
  </div>
</template>

<script>
import WeeOrderModal from "@/components/integrations/wee-company/WeeOrderModal";
export default {
  name: "IntegrationModal",
  components: {WeeOrderModal},
  methods: {
    onRunWeeCompany() {
      this.$parent.close();
      this.$buefy.modal.open({
        parent: this,
        component: WeeOrderModal,
        hasModalCard: true,
        trapFocus: true,
        fullScreen: true
      });
    }
  }
}
</script>

<style scoped>

</style>
