<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Factura</p>
      <button
          type="button"
          class="delete"
          @click="$parent.close()"/>
    </header>
    <section class="modal-card-body">
      <div class="content">
        <b-field grouped>
          <p class="control">
            <strong>RFC: </strong> {{ invoice.rfc }} <br>
            <strong>Serie: </strong> {{ invoice.serie }} <br>
            <strong>Folio: </strong> {{ invoice.folio }} <br>
            <strong>Fecha: </strong> {{ invoice.datetime }} <br>
            <b-tooltip label="Ver Factura">
              <button type="button" class="button is-danger" @click="showPDF = true">
                <b-icon icon="file-pdf"></b-icon>
              </button>
            </b-tooltip>
          </p>
        </b-field>
        <div class="columns">
          <div class="column is-3">
            <b-field label="Email">
              <b-input v-model="email"/>
            </b-field>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button native-type="button" type="is-primary" @click="$parent.close()">Cerrar</b-button>
      <b-button native-type="button" type="is-default" @click="handleSendMail">Enviar por correo</b-button>
    </footer>
    <b-modal v-model="showPDF">
      <iframe class="lapi__iframe" :src="`${url}/order-invoice/${invoice.uuid}/pdf/download`"></iframe>
    </b-modal>
  </div>
</template>

<script>
export default  {
  name: "OrderInvoice",
  props: {
    invoice: Object,
    orderId: Number
  },
  data() {
    return {
      showPDF: false,
      email: ''
    }
  },
  computed: {
    url() {
      return process.env.VUE_APP_API_URL;
    }
  },
  methods: {
    handleSendMail(){
      this.$loader.show();
      this.$http.post(`/invoice/email/${this.orderId}`, {email: this.email}).then(({data})=> {
        this.$toast.success(data.message);
      }).catch(() => {
      }).finally(() => {
        this.$loader.hide();
      })
    }
  }
}
</script>

<style scoped>
.lapi__iframe{
  width: 100%;
  height: 80vh;
}
</style>