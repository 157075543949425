<template>
  <div>
    <div class="columns">
      <div class="column is-1">
        <b-field>
          <button class="button is-primary has-icon"
                  @click="showModal(0)"
                  type="button">
            <b-icon icon="search"/>
          </button>
        </b-field>
      </div>
      <div class="column is-3">
        <b-field label="Clave">
          <b-input
              @keypress.enter.native="onSearch(0)"
              v-model="diagnostics[0].code"/>
        </b-field>
      </div>
      <div class="column is-8">
        <b-field label="Descripción">
          <b-input v-model="diagnostics[0].name" disabled="true"/>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column is-1">
        <b-field>
          <button class="button is-primary has-icon"
                  @click="showModal(1)"
                  type="button">
            <b-icon icon="search"/>
          </button>
        </b-field>
      </div>
      <div class="column is-3">
        <b-field label="Clave">
          <b-input
              @keypress.enter.native="onSearch(1)"
              v-model="diagnostics[1].code"/>
        </b-field>
      </div>
      <div class="column is-8">
        <b-field label="Descripción">
          <b-input v-model="diagnostics[1].name" disabled="true"/>
        </b-field>
      </div>
    </div>
    <b-field v-if="canUpdate">
      <button class="button is-primary is-rounded"
              @click="onUpdate"
              type="button">
        Actualizar Diagnostico
      </button>
    </b-field>
    <b-modal :active.sync="isComponentModalActive"
             has-modal-card full-screen :can-cancel="false">
      <ModalSelectDiagnostic :index="selected"/>
    </b-modal>
  </div>
</template>

<script>
import {mapState} from "vuex";
import ModalSelectDiagnostic from "@/components/order/ModalSelectDiagnostic";

export default {
  name: "OrderDiagnostics",
  components: { ModalSelectDiagnostic },
  props: {
    data: Object,
    canUpdate: Boolean,
  },
  data() {
    return {
      isComponentModalActive: false,
      selected: 0
    }
  },
  computed: {
    ...mapState('order', ['diagnostics'])
  },
  mounted() {
  },
  methods: {
    onSearch(index){
      this.$loader.show();
      this.$http.get(`/cie10-catalog/${this.diagnostics[index].code}`).then(response => {
        this.$store.dispatch('order/setDiagnostic', {value:response.data.data, pos:index})
      }).catch((errors) => {
        this.$alfalab.showError(errors);
      }).finally(() => {
        this.$loader.hide();
      })
    },
    onUpdate(){
      this.$loader.show();
      this.$http.put(`/order-update-observation/${this.data.id}`, {
        diagnostics: this.diagnostics,
        action: 'updateDiagnostics'
      }).then(({data}) => {
        this.$loader.hide();
        this.$toast.success(data.message);
      }, error => {
        this.$loader.hide();
      });
    },
    showModal(index){
      this.selected = index;
      this.isComponentModalActive = true;
    }
  }
}
</script>

<style scoped>

</style>
