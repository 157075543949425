<template>
  <div class="modal-card" style="width: auto; height: 800px">
      <header class="modal-card-head">
        <p class="modal-card-title">Interfaz Báscula InBody</p>
        <button
        type="button"
        class="delete"
        @click="$parent.close()"/>
      </header>
    <section class="modal-card-body">
      <div class="column is-12">
        <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 16px;">
          <b-tooltip label="Leer">
            <b-button icon-left="circle-play" size="is-large" type="is-primary"/>
          </b-tooltip>
          <b-tooltip label="Editar"><b-button icon-left="pen-to-square" size="is-large" type="is-primary"/></b-tooltip>
          <b-tooltip label="Guardar"><b-button icon-left="floppy-disk" size="is-large" type="is-primary"/></b-tooltip>
        </div>
      </div>

      <div class="column is-12 columns is-multiline">
        <div class="column is-3">
          <b-field label="Fecha"
                    label-position="on-border">
            <b-datepicker/>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label="Orden InBody"
                    label-position="on-border">
            <b-input>
            </b-input>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Nombre"
                    label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
      </div>

      <div class="column is-12 columns is-multiline">
        <div class="column is-6">
          <b-field label="Nombre"
                    label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label="ID"
                  label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label="ALTURA"
                    label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label="EDAD"
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label="SEXO"
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="FECHA DE LA PRUEBA"
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
      </div>

      <div class="is-divider" data-content="Análisis de la composición corporal"></div>
      <div class="column is-12 columns is-multiline">
        <div class="column is-4">
          <b-field label="AGUA CORPORAL TOTAL (L)"
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="LIMITE INFERIOR"
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="LIMITE SUPERIOR"
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="PROTEÍNAS (kg)"
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="LIMITE INFERIOR"
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="LIMITE SUPERIOR"
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="MINERALES (kg)"
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="LIMITE INFERIOR"
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="LIMITE SUPERIOR"
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="MASA GRASA CORPORAL (kg)"
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="LIMITE INFERIOR"
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="LIMITE SUPERIOR"
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
      </div>

      <div class="is-divider" data-content="Análisis Músculo-grasa"></div>
      <div class="column is-12 columns is-multiline">
          <div class="column is-4">
            <b-field label="PESO (kg)"
                     label-position="on-border">
              <b-input></b-input>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field label=""
                     label-position="on-border">
              <b-input></b-input>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field label=""
                     label-position="on-border">
              <b-input></b-input>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field label="MASA MUSCULOESQUELÉTICA (kg)"
                     label-position="on-border">
              <b-input></b-input>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field label=""
                     label-position="on-border">
              <b-input></b-input>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field label=""
                     label-position="on-border">
              <b-input></b-input>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field label="MASA GRASA CORPORAL (kg)"
                     label-position="on-border">
              <b-input></b-input>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field label=""
                     label-position="on-border">
              <b-input></b-input>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field label=""
                     label-position="on-border">
              <b-input></b-input>
            </b-field>
          </div>
        </div>

      <div class="is-divider" data-content="Análisis de obesidad"></div>
      <div class="column is-12 columns is-multiline">
        <div class="column is-4">
          <b-field label="IMC (kg/m2)"
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="PORCENTAJE DE GRASA CORPORAL (%)"
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
      </div>

      <div class="is-divider" data-content="Análisis de Magro por Segmentos"></div>
      <div class="column is-12 columns is-multiline">
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
      </div>

      <div class="is-divider" data-content="Análisis de Grasa Segmental"></div>
      <div class="column is-12 columns is-multiline">
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label=""
                   label-position="on-border">
            <b-input></b-input>
          </b-field>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button native-type="button" type="is-primary"  @click="$parent.close()">Cerrar</b-button>
    </footer>
  </div>
</template>

<script>
export default {
  name: "DataInBody"
}
</script>

<style scoped>

</style>