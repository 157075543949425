<template>
    <div class="columns is-gapless">
        <div class="column is-8 has-background-white">
            <div class="content" style="padding: 10px">
                <select-study :saved="{}"></select-study>
                <pos-order-study></pos-order-study>
            </div>
        </div>
        <div class="column is-4 has-background-grey-lighter">
            <div class="section is-paddingless-top">
               <div >
                    <select-modal-patient></select-modal-patient>
               </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PosOrderStudy from "../../components/order/PosOrderStudy";
    import SelectStudy from "../../components/study/SelectStudy";
    import SelectModalPatient from "../../components/patient/SelectModalPatient";
    export default {
        name: "CaptureOrder",
        components: {SelectModalPatient, SelectStudy, PosOrderStudy},
        data() {
            return {
                data: {
                    studies: []
                },
                studies: []
            }
        }
    }
</script>

<style scoped>

</style>
