<template>
  <b-field :label="label"
           required>
    <b-select :loading="loading"
              :value="value"
              @input="val => $emit('input', val)"
              :placeholder="placeholder">
      <option v-for="item in options"
              :value="item[defaultValue]"
              :key="item[defaultValue]"
      >
        {{ item[defaultText]  }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
export default {
  name: "SelectAuthorize",
  props: {
    label: {
      type: String,
      default: 'Autorizan'
    },
    placeholder: {
      type: String,
      default: 'Selecciona quien autoriza'
    },
    defaultValue: {
      type: String,
      default: 'code'
    },
    defaultText: {
      type: String,
      default: 'name'
    },
    required: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    value: {},
  },
  data() {
    return {
      loading: false,
      options: [],
      apiUrl: 'authorizers'
    }
  },
  created() {
    this.prepareComponent();
  },
  methods: {
    async prepareComponent() {
      this.loading = false;
      await this.getAll()
          .then(data => {
            this.options = data.data || [];
            console.log(this.options);
          })
          .catch(data => {

          });

      this.loading = false;
    }
  }
}
</script>

<style scoped>

</style>
