<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">Aplicar Tarjeta de descuento.</p>
        </header>
        <section class="modal-card-body">
            <div class="columns is-multiline">
                <div class="column is-6">
                    <select-discount-card v-model="discountId"
                        :options="discountCards"
                    ></select-discount-card>
                </div>
                <div class="column">
                    <div class="content">
                        <table>
                            <thead>
                           <tr>
                               <th></th>
                               <th>Gabinete</th>
                               <th>Laboratorio</th>
                               <th>Total</th>
                           </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Total</td>
                                <td>{{ totalGab }}</td>
                                <td>{{ totalLab }}</td>
                                <td>{{ totalLab + totalGab }}</td>
                            </tr>
                            <tr>
                            <td>Descuento</td>
                            <td>{{ totalDiscountGab }}</td>
                            <td>{{ totalDiscountLab }}</td>
                            <td>{{ totalDiscountGab + totalDiscountLab }}</td>
                            </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colspan="2"></th>
                                    <th>Importe sin IVA</th>
                                    <th>{{ Number((totalLab + totalGab) - (totalDiscountGab + totalDiscountLab)).toFixed(2) }}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">
                Cerrar
            </button>
            <button class="button" type="button" @click="onApplyDiscount(false)">
                Aplicar descuento
            </button>
            <button class="button is-primary" type="button" @click="onApplyDiscount(true)">
              Aplicar descuento y Cerrar
            </button>
        </footer>
    </div>
</template>

<script>
    import SelectDiscountCard from "./SelectDiscountCard";
    import {mapGetters} from 'vuex';
    export default {
        name: "ModalDiscountCard",
        components: {SelectDiscountCard},
        props: {
            value: {}
        },
        data() {
            return {
                discountId: null,
                localTotal: 0
            }
        },
        computed: {
            discountCards() {
                return this.$store.state.main.discountCards || [];
            },
            applyDiscount() {
                return this.discountCards.find(el => el.id === this.discountId) || {};
            },
            ...mapGetters('order', [
                'totalLab', 'totalGab', 'totalDiscountGab', 'totalDiscountLab'
            ])
        },
        methods: {
            onApplyDiscount(close = false) {
                this.$emit('update:discountLab', this.applyDiscount.desc_lab);
                this.$emit('update:discountGab', this.applyDiscount.desc_gab);
                this.$emit('input', this.applyDiscount.id);
                this.$store.dispatch('order/applyDiscount', this.applyDiscount);
                if (close) {
                    this.$parent.close();
                }
            }
        }
    }
</script>

<style scoped>

</style>