<template>
  <b-field :label="label"
           :label-position="labelPosition">
    <b-select :placeholder="placeholder"
              :loading="loading"
              :readonly="readonly"
              expanded
              :disabled="value === 0 || !subBranchOffices.length"
              @input="val => {$emit('input', val); $store.dispatch('order/setSubBranchOffice', val)}"
              :value="value">
      <option :value="null">Ninguna</option>
      <option
          v-for="option in subBranchOffices"
          :value="option[optionValue]"
          :key="`sub-branch-office-${option[optionValue]}`">
        {{ option[optionText] }}
      </option>
    </b-select>
  </b-field>
</template>

<script>

export default {
  name: "SelectSubBranchOffice",
  data() {
    return {
      loading: false,
      apiUrl: 'sub-branch-offices'
    }
  },
  props: {
    placeholder: String,
    label: String,
    labelPosition: String,
    readonly: Boolean,
    disabled: Boolean,
    value: {},
    branchOffice: String | Number,
    optionText: {
      type: String,
      default: 'name'
    },
    optionValue: {
      type: String,
      default: 'id'
    },
  },
  computed: {
    subBranchOffices() {
      return this.$store.state.main.subBranchOffices.filter(el => Number(el.branch_office_id) === Number(this.branchOffice) );
    }
  },
}
</script>

<style scoped>

</style>
