<template>
  <b-field label="Buscar Estudios" label-position="on-border" grouped>
    <b-input @keyup.enter.native="getData"
             @keyup.114.native="handleSuccess"
             ref="search_study"
             v-model="search"
             :loading="loading">
    </b-input>
    <b-checkbox
        v-if="!$alfalab.enableForeignWorkUI($auth.user()) && canBlock"
        v-model="saved.studies">
      <b-icon icon="lock"/>
    </b-checkbox>
    <b-checkbox v-model="byCode">
      Por clave
    </b-checkbox>
    <p class="control">
      <b-button class="button is-primary"
                type="button"
                @click="getData"
                :class="{'is-loading':loading}">
        <b-icon icon="search"></b-icon>
      </b-button>
    </p>
    <b-modal :active.sync="isComponentModalActive"
             has-modal-card full-screen :can-cancel="false">
      <modal-select-study :data="data"
                          :paginated="paginated"
                          @select="val => $emit('selectStudy', val)"
                          @search="onModalSearch"
      >
      </modal-select-study>
    </b-modal>
  </b-field>
</template>

<script>
import ModalSelectStudy from "./ModalSelectStudy";
import {mapGetters} from 'vuex';

export default {
  name: "SelectStudy",
  data() {
    return {
      isComponentModalActive: false,
      data: [],
      params: {},
      loading: false,
      search: '',
      paginated: false,
      both: false
    }
  },
  components: {
    ModalSelectStudy
  },
  props: {
    apiUrl: {
      type: String,
      default: 'studies'
    },
    company: {
      type: String|Object,
    },
    saved: Object,
    canBlock: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  computed: {
    byCode: {
      get() {
        return this.$store.state.main.typeFindStudies;
      },
      set(value) {
        this.$store.commit('setFindTypeStudies', value)
      }
    },
    ...mapGetters('order',[
        'priceList', 'readOnly'
    ])
  },
  mounted() {
    this.prepareData();
    this.$store.subscribeAction((action, state) => {
      if (action.type === 'order/setPatient' && !this.readOnly && this.$refs.search_study) {
        if(!this.$alfalab.isFastMode(this.$auth.user())){
          this.$refs.search_study.focus();
        }
      }
    });
  },
  methods: {
    prepareData() {
      const defaultPriceList = this.$store.getters.authPriceList;
      this.params = {
        page: 1,
        perPage: 200,
        price_list: defaultPriceList,
        company: this.company.company
      }
      switch (this.type){
        case 'order':
          this.params.price_list = this.priceList;
          break;
        default:
          if(this.company && this.company.price_list){
            this.params.price_list = this.company.price_list;
          }
          break;
      }
    },
    async getData() {
      this.prepareData();
      if(!this.both){
        if (this.byCode) {
          this.params.code = this.search;
        } else {
          this.params.q = this.search;
        }
      }else{
        this.params.all = this.search;
      }

      if (!this.loading) {
        this.paginated = true;
        this.loading = true;
        await this.getStudies();
        this.loading = false;
      }
    },
    async handleSuccess(event) {
     this.paginated = false;
      this.prepareData();
      if (event.altKey) {
        this.loading = true;
        this.params.perPage = '*';
        await this.getStudies();
        this.loading = false;
      }
    },
    handleWrong(e) {
      console.log(e);
    },
    getStudies() {
      return this.getAll(this.params)
          .then(({data = []}) => {
            this.data = data;
            this.search = '';
            if (!this.isComponentModalActive && this.data.length === 1) {
              //console.log(this.isComponentModalActive + " " + JSON.stringify(this.params));
              this.$emit('selectStudy', data[0]);
            } else {
              this.isComponentModalActive = true;
            }
          })
    },
    async onModalSearch(query){
      this.byCode = false;
      this.both = true;
      this.search = JSON.stringify(query);
      await this.getData();
      this.both = false;
    }
  }
}
</script>

<style scoped>

</style>
