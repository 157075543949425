<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Documentos de orden</p>
      <button
        type="button"
        class="delete"
        @click="$parent.close()"/>
    </header>
    <section class="modal-card-body">
      <div class="content">
        <div class="columns is-multiline">
          <div class="column is-3-desktop is-2-widescreen" v-if="!!order">
            <div class="card" @click="showPdfNoticePrivacy=true">
              <div class="card-image">
                <figure class="image">
                  <img src="https://gateway2.lapi.mx/img/documentos-orden.png" style="margin-top: 40px">
                </figure>
                <div class="card-content">
                  <p class="subtitle">Aviso de privacidad</p>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-3-desktop is-2-widescreen" v-if="false">
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <img src="https://gateway2.lapi.mx/img/documentos-orden.png" style="margin-top: 40px">
                </figure>
                <div class="card-content">
                  <p class="subtitle">VIH</p>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-3-desktop is-2-widescreen" v-if="false">
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <img src="https://gateway2.lapi.mx/img/documentos-orden.png" style="margin-top: 40px">
                </figure>
                <div class="card-content">
                  <p class="subtitle">Documento</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button native-type="button" type="is-primary" @click="showModal=true">Registrar firma</b-button>
      <b-button native-type="button" type="is-primary" @click="$parent.close()">Cerrar</b-button>
      <b-button v-if="showMailButton" native-type="button" type="is-default" @click="handleSendMail">Enviar por correo al paciente</b-button>
    </footer>
    <b-modal v-model="showModal">
      <order-sign-documents
          @order-update-patient-sign="$emit('order-update-patient-sign')"
          @close-order-documents="$parent.close()"/>
    </b-modal>
    <b-modal v-model="showPdfNoticePrivacy">
      <iframe class="lapi__iframe" :src="`${urlNoticePrivacy}`"></iframe>
    </b-modal>
  </div>
</template>

<script>
import OrderSignDocuments from "@/components/order/OrderSignDocuments";
export default {
  name: "OrderDocuments",
  components: {
    OrderSignDocuments
  },
  props: {
    order: String,
    data: Object
  },
  data() {
    return {
      showModal: false,
      showPdfNoticePrivacy: false
    }
  },
  computed: {
    urlNoticePrivacy() {
      if(!process.env.VUE_APP_API_URL.toString().startsWith('https')){
        return 'http://api-gateway.lapi.site/v1/documents/notice-privacy/' + this.order;
      }else{
        return 'https://gateway2.lapi.mx/v1/documents/notice-privacy/' + this.order;
      }
    },
    showMailButton() {
      return this.data.patient && this.data.patient.code && !!this.order
    }
  },
  methods: {
    handleSendMail() {
      if(!(this.data.patient && this.data.patient.code && this.data.patient.email)){
        this.$buefy.dialog.alert({
          title: 'Falta información',
          message: `El paciente no tiene un correo registrado`,
          type: 'is-danger',
          hasIcon: true,
          ariaModal: true
        })
      }else{
        this.$loader.show();
        this.$http.post(`/documents/send-mail/${this.order}`).then(() => {
          this.$toast.success('Aviso de privacidad enviado con éxito');
        }).finally(() => {
          this.$loader.hide();
        })
      }
    }
  }
}
</script>

<style scoped>
.lapi__iframe {
  width: 100%;
  height: 80vh;
}
</style>