<template>
  <div class="block">
    <b-checkbox :disabled="! enablePayInCash" size="is-small"
                @input="agreement=false"
                v-model="payInCash">
      Pago en caja
    </b-checkbox>
    <b-checkbox :disabled="!enableCopay" v-model="coPay" size="is-small">
      Copago
    </b-checkbox>
    <b-checkbox :disabled="!enableAgreement" size="is-small" v-model="agreement">
      Convenio
    </b-checkbox>
  </div>
</template>

<script>
export default {
  name: "CompanyFormConditions",
  props: {
    readOnly: Boolean,
    company: Object,
    disabled: Boolean
  },
  computed: {
    enableAgreement() {
      if(this.readOnly){
        return false;
      }
      return this.company.can_able_agreement && this.payInCash;
    },
    enablePayInCash() {
      if (this.readOnly) {
        return false
      } else {
        return this.company.can_able_pay_in_cash && !this.coPay
      }
    },
    enableCopay() {
      if (this.readOnly) {
        return false
      } else {
        return this.company.can_able_copay && !this.payInCash;
      }
    },
    payInCash: {
      get () {
        return this.$store.state.order.payInCash;
      },
      set (value) {
        this.$store.commit('order/setPayInCash', value)
      }
    },
    coPay: {
      get () {
        return this.$store.state.order.coPay;
      },
      set (value) {
        this.$store.dispatch('order/setCopay', value);
      }
    },
    agreement: {
      get() {
        return this.$store.state.order.agreement;
      },
      set(value) {
        this.$store.commit('order/setAgreement', value)
      }
    }
  }
}
</script>

<style scoped>

</style>
