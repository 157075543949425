<template>
    <div class="columns is-multiline">
        <div class="column is-3 is-2-fullhd has-text-centered" v-for="item in data">
            <div class="box">
                <b-icon icon="vial" size="is-large"
                        :style="{color: `#${item.color}`}"
                        v-if="item.color"></b-icon>
                <b-icon icon="file" size="is-large" v-else></b-icon>
                <p>{{ item.container }}</p>
                <p>{{ item.sample_material }}</p>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "OrderPosSamples",
        props: {
            data: Array
        }
    }
</script>

<style scoped>

</style>