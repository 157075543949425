import { render, staticRenderFns } from "./StudyConfigs.vue?vue&type=template&id=797e369e&scoped=true"
import script from "./StudyConfigs.vue?vue&type=script&lang=js"
export * from "./StudyConfigs.vue?vue&type=script&lang=js"
import style0 from "./StudyConfigs.vue?vue&type=style&index=0&id=797e369e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "797e369e",
  null
  
)

export default component.exports