<template>
  <div>
    <figure class="image" style="max-width:256px;margin: 0 auto;" v-if="printerManager !== $lapi_printer_managers.VFP">
      <img src="/img/print.gif" alt="Imprimiendo">
    </figure>
    <b-tabs v-model="activeTab" v-if="printerManager === $lapi_printer_managers.VFP && data.docs.length > 0">
      <b-tab-item v-for="(value, key) of data.docs" :key="key" :label="`Documento ${key + 1}`">
        <iframe :src="value.url" frameborder="0" class="lapi-iframe"/>
      </b-tab-item>
    </b-tabs>
  </div>

</template>

<script>

export default {
	name: "ModalPrintJob",
	props: {
		data: Object,
		hasData: Boolean,
    printerManager: String
	},
  data(){
	   return {
       activeTab: 0
     }
  },
	mounted() {
	  const printManager = localStorage.getItem('printerManager') || this.$lapi_printer_managers.jsprint;
	  switch (printManager){
	    default:
	    case this.$lapi_printer_managers.jsprint:
        this.jsPrintRoutine();
	      break;
      case this.$lapi_printer_managers.lapiPrint:
        this.lapiPrintRoutine();
        break;
      case this.$lapi_printer_managers.VFP:
        this.vfpPrintRoutine();
        break;
    }
	},
  methods: {
	  jsPrintRoutine(){
      if (this.hasData) {
        const printers = JSON.parse(localStorage.getItem('printers') || '[]');
        const labelPrinter = printers.find(el => el.use === 'labels');
        const docsPrinter = printers.find(el => el.use === 'docs');
        const vm = this;
        let prints = 0;
        if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
          const cpjg = new JSPM.ClientPrintJobGroup();
          if (labelPrinter) {
            let labels = vm.data.labels || [];
            labels.forEach(el => {
              const cpjlabel = new JSPM.ClientPrintJob();
              cpjlabel.clientPrinter = new JSPM.InstalledPrinter(labelPrinter.name);
              cpjlabel.printerCommands = el;
              cpjg.jobs.push(cpjlabel);
              prints++;
            })

          }

          if (docsPrinter) {
            let docs = vm.data.docs || [];
            docs.forEach(el => {
              let cpjdoc = new JSPM.ClientPrintJob();
              cpjdoc.clientPrinter = new JSPM.InstalledPrinter(docsPrinter.name);
              for (let i = 0; i < el.copies; i++) {
                let doc_file = new JSPM.PrintFilePDF(el.url, JSPM.FileSourceType.URL, `${el.name}_${i}.pdf`, 1);
                cpjdoc.files.push(doc_file);
              }
              cpjg.jobs.push(cpjdoc);
              prints++;
            })

          }

          if(prints > 0){
            cpjg.sendToClient();
          }


          setTimeout(() => {
            vm.$parent.close();
          }, 5000)
        }
      }
    },
    vfpPrintRoutine(){
      const vm = this;
      if(this.hasData){
        if(this.data.docs.length < 1 ){
          vm.$parent.close();
        }
      }
    },
    lapiPrintRoutine(){
      const vm = this;
	    if(this.hasData){
        setTimeout(() => {
          vm.$parent.close();
        }, 5000)
      }
    }
  }
}
</script>

<style scoped>
.lapi-iframe{
  width: 100%;
  height: 100vh;
}
</style>
