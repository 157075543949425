<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <div class="columns is-multiline">
        <div class="column is-12">
          <p class="modal-card-title">Paciente de WeeCompany</p>
        </div>
        <div class="column is-12">
            <b-button type="is-primary" icon-left="check" :disabled="disabled">Aceptar</b-button>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <b-button type="is-danger is-light" icon-left="times-circle" @click="$parent.close()">Cancelar</b-button>
        </div>
      </div>
    </header>
    <section class="modal-card-body">
      <div class="columns is-multiline">
        <div class="column is-2">
          <b-field label="Folio">
            <b-input v-model="folio" />
          </b-field>
        </div>
        <div class="column is-1">
          <b-field>
            <b-button icon-left="search"  @click="onSearch">Buscar</b-button>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label="Paterno">
            <b-input v-model="data.last_name" :disabled="true"/>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label="Materno">
            <b-input v-model="data.other_name" :disabled="true"/>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label="Nombre(s)">
            <b-input v-model="data.name" :disabled="true"/>
          </b-field>
        </div>
        <div class="column is-3"> </div>
        <div class="column is-9">
          <b-field>
            <b-input v-model="data.full_name" :disabled="true"/>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label="Sexo">
            <b-select v-model="data.gender" :disabled="true">
              <option value="M">HOMBRE</option>
              <option value="F">MUJER</option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label="F. Nacimiento">
            <b-datepicker  v-model="data.birthday" :disabled="true"/>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label="RFC">
            <b-input v-model="data.rfc" :disabled="true"/>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label="CURP">
            <b-input v-model="data.curp" :disabled="true"/>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Teléfonos">
            <b-input v-model="data.phones" :disabled="true"/>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Email">
            <b-input v-model="data.email" native-type="email" :disabled="true"/>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label="Empresa">
            <b-input v-model="data.company.code" v-if="data.company" :disabled="true"/>
            <b-input v-else :disabled="true"/>
          </b-field>
        </div>
        <div class="column is-9">
          <b-field >
            <b-input v-model="data.company.name" v-if="data.company" :disabled="true"/>
            <b-input v-else :disabled="true"/>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label="Código de Validación">
            <b-input v-model="token" />
          </b-field>
        </div>
        <div class="column is-3">
          <b-field>
            <b-button type="is-success" icon-left="check" @click="onValidate" :disabled="!data.id"/>
          </b-field>
        </div>
      </div>
      <div class="table-container">
        <b-table :data="data.studies">
          <b-table-column field="code" label="Estudio" v-slot="props">
            {{ props.row.code }}
          </b-table-column>
          <b-table-column field="name" label="Concepto" v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column field="price" label="Precio" v-slot="props">
            {{ props.row.price }}
          </b-table-column>
          <b-table-column field="folio" label="Poliza" v-slot="props">
            {{ props.row.folio }}
          </b-table-column>
          <b-table-column field="selected" label="Solicitar" v-slot="props">
            <b-checkbox v-model="data.studies[props.row.integration_index].selected" />
          </b-table-column>
        </b-table>
      </div>

    </section>
  </div>
</template>

<script>
export default {
  name: "WeeOrderModal",
  data() {
    return {
      folio: '',
      token: '',
      disabled: true,
      data: {

      }
    }
  },
  methods: {
    onSearch() {
      this.data = {};
      this.$loader.show();
      this.$http.post('/integration/execute/wee-company/find', {
        folio: this.folio
      }).then(({data}) => {
        //console.log(data)
        const status = data.data.IsOk;
        if(status){
          const rows = data.data.NoFilas;
          if(rows){
            const patient = {
              id: data.data.dsRespuesta.Datos[0].idPersona,
              id_service: data.data.dsRespuesta.Datos[0].idServicio,
              id_order: data.data.dsRespuesta.Datos[0].idOrden,
              name: data.data.dsRespuesta.Datos[0].Nombre,
              last_name: data.data.dsRespuesta.Datos[0].Paterno,
              other_name: data.data.dsRespuesta.Datos[0].Materno,
              full_name: `${data.data.dsRespuesta.Datos[0].Paterno} ${data.data.dsRespuesta.Datos[0].Materno} ${data.data.dsRespuesta.Datos[0].Nombre}`,
              curp: data.data.dsRespuesta.Datos[0].CURP,
              rfc: data.data.dsRespuesta.Datos[0].RFC,
              birthday: new Date(data.data.dsRespuesta.Datos[0].FechaNacimiento),
              phones: data.data.dsRespuesta.Datos[0].Telefono_Paciente,
              email: data.data.dsRespuesta.Datos[0].Correo_Paciente,
              gender: data.data.dsRespuesta.Datos[0].codGenero,
              idTPA: data.data.dsRespuesta.Datos[0].idTPA
            }
            const studies = [];
            let index = 0;
            for(const i of data.data.dsRespuesta.Datos){
              if(i.ClaveInterna){
                studies.push({
                  code: i.ClaveInterna,
                  name: i.DescripcionInterna,
                  folio: i.Poliza,
                  integration_info: i,
                  integration_index: index++,
                  selected: true
                });
              }
            }
            this.data = Object.assign({}, this.data, patient, {studies});
            this.data = Object.assign({}, this.data, {company: data});
            return this.getPrices();
          }else{
            this.$buefy.dialog.alert({
              title: 'Error al realizar la petición',
              message: 'No existe registro de consulta para datos del paciente'
            })
          }
        }else{
          this.$buefy.dialog.alert({
            title: 'Error al realizar la petición',
            message: 'El folio ingresado no es válido'
          })
        }
        return new Promise((resolve, reject) => {
          reject({message: 'Error en el recurso solicitado'});
        })
      }).then(({data}) => {
        return this.$http.post('/integration/execute/wee-company/send', {
          service: this.data.id_service,
          patient: this.data.id
        });
      }).then(({data}) => {
        console.log(data.data);
        this.$toast.success('Se ha enviado el código de validación');
        return this.$http.get('/integration/find-company/' + this.data.idTPA)
      }).catch(error => {
        this.$alfalab.showError(error);
      }).finally(() => {
        this.$loader.hide();
      })
    },
    getPrices(){
      return new Promise(async (resolve, reject) => {
        for(const i of this.data.studies){
          await this.$http.post('/integration/execute/wee-company/prices', {
            service: i.integration_info.idServicio
          }).then(({data}) => {
            console.log(data.data);
            if(data.data.IsOk){
              this.data.studies[i.integration_index].price = data.data.dsRespuesta.Datos[0].PrecioUnitario;
              this.data.studies[i.integration_index].iva = data.data.dsRespuesta.Datos1[0].IVAAplicadoPaciente * 100;
              this.data = Object.assign({}, this.data);
            }else{
              reject('Not Found')
            }
          });
        }
        resolve(true);
      });
    },
    onValidate(){
      this.$loader.show();
      this.$http.post('/integration/execute/wee-company/prices', {
        patient: this.data.id,
        token: this.token,
        order: this.data.id_order
      }).then(({data}) => {
        this.disabled = false;
        this.$toast.success('Código validado con éxito');
      }).catch(error => {
        this.$alfalab.showError(error);
      }).finally(() => {
        this.$loader.hide();
      })
    }
  }
}
</script>

<style scoped>

</style>
