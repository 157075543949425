<template>
  <div class="modal-card" style="min-width: 700px">
    <header class="modal-card-head">
      <p class="modal-card-title">Pago</p>
    </header>
    <section class="modal-card-body">
      <template v-if="!readOnly || $alfalab.canUpdateOrderPayments($auth.user(), data)">
        <b-field grouped group-multiline>
          <select-payment-type
              :default-options="availableMethods"
              v-model="type"
          ></select-payment-type>
          <b-field label="Pago">
            <b-input type="number"
                     :max="remaining"
                     min="0"
                     step="0.01"
                     v-model="amount"
                     :disabled="! canAddPayment">
            </b-input>
          </b-field>
          <b-field label="4 digitos"
                   v-if="type && type.match(/^(TARJD|TARJT|SRPAD|SRPAG|INTCR|INTDE)$/)">
            <b-input type="number"
                     style="max-width: 6em"
                     v-model="last_four">
            </b-input>
          </b-field>
          <b-field label="Referencia"
                   v-if="type && type.match(/^(NOTAC|PAGIN|SRPAD|SRPAG|INTCR|INTDE|TCREB|TDEBB)$/)">
            <b-input type="text" v-model="references">
            </b-input>
          </b-field>
          <div class="field">
            <button class="button is-info"
                    :disabled="! canAddPayment"
                    type="button"
                    v-if="!isForeignCoin"
                    @click="onPayment">
              <b-icon icon="plus"></b-icon>
            </button>
          </div>
        </b-field>
        <div v-if="isForeignCoin">
          <hr>
          <b-field grouped>
            <b-field
                label="T. Cambio">
              <b-input type="number" :value="rateExchange" :disabled="true"/>
            </b-field>
            <b-field
                label="Pago Moneda Extranjera">
              <b-input type="number" v-model="foreignImport"/>
            </b-field>
            <div class="field">
              <button class="button is-info"
                      :disabled="! canAddPayment"
                      type="button"
                      @click="onPayment">
                <b-icon icon="plus"></b-icon>
              </button>
            </div>
          </b-field>
        </div>
        <hr>
        <b-field grouped v-if="type === 'CTCIA'">
          <select-authorize v-model="data.authorizer"></select-authorize>
          <b-field label="Código de autorización">
            <b-input type="number" v-model="data.authorization_code"></b-input>
          </b-field>
        </b-field>
      </template>
      <b-table :data="payments">
        <b-table-column label="Acciones"
                        v-slot="props">
          <button class="button is-danger is-small is-outlined"
                  type="button"
                  v-if="!readOnly || $alfalab.canUpdateOrderPayments($auth.user(), data)"
                  @click="onDeleteItem(props.index)">
            <b-icon icon="times"></b-icon>
          </button>
        </b-table-column>
        <b-table-column label="Método de pago"
                        v-slot="props">
          {{ methods.find(el => el.code === props.row.type )[searchDescription] }}
        </b-table-column>
        <b-table-column label="Pago"
                        v-slot="props">
          {{ props.row.amount | currency }}
          <span v-if="props.row.foreign_import > 0">({{ Number(props.row.foreign_import || 0).toFixed(2) }} {{props.row.method.coin.name}}) </span>
        </b-table-column>
        <template slot="footer" class="dd">
          <th colspan="2"></th>
          <th>Total : {{ amountTotal }}</th>
        </template>
      </b-table>
      <b-field label="Nota" style="max-width: 200px;">
        <b-input maxlength="8" readonly v-model="data.note"></b-input>
      </b-field>
      <template v-if="hasPaymentCash && leftover > 0">
        <h1 class="title">Cambio: {{ leftover | currency }}</h1>
      </template>
      <div class="is-pulled-right">
        <h1 class="title" :class="{'has-text-danger': remaining > 0}">
          Restante: {{ remaining | currency }}
        </h1>
        <h1 class="title">
          Importe: {{ total | currency }}
        </h1>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Cerrar</button>
      <button class="button is-primary"
              type="button"
              @click="onPaid"
              v-if="!readOnly"
              :disabled="remaining > 0">
        Cobrar
      </button>
      <button class="button is-primary"
              type="button"
              @click="onUpdatePaid"
              v-if="readOnly && $alfalab.canUpdateOrderPayments($auth.user(), data)"
              :disabled="remaining > 0">
        Actualizar Pagos
      </button>
    </footer>
  </div>
</template>

<script>
import SelectDiscountCard from "../discount/SelectDiscountCard";
import SelectPaymentType from "../payment-type/SelectPaymentType";
import {mapState} from 'vuex';
import SelectAuthorize from "@/components/payment-type/SelectAuthorize";

export default {
  name: "PaymentModalOrder",
  components: {SelectAuthorize, SelectPaymentType, SelectDiscountCard},
  props: {
    total: String | Number,
    subtotal: String | Number,
    tax: String | Number,
    folio: String|Number,
    data: Object,
    readOnly: Boolean,
  },
  data() {
    return {
      type: 'EFECT',
      amount: 0,
      columns: [
        {label:'Método de pago', field: 'type'},
        {label:'Método de pago', field: 'amount'},
      ],
      last_four: '',
      references: '',
      searchDescription: 'description',
      foreignImport: 0
    }
  },
  computed: {
    ...mapState('order/orderPayments', [
      'payments'
    ]),
    leftover() {
      return this.amountTotal - Number(this.totalFixed);
    },
    totalFixed() {
      return Number(this.total.toFixed(2))
    },
    remaining() {
      let total = this.payments.reduce((acc, el) => {
        return acc += Number(el.amount);
      }, 0);

      return Number(this.total.toFixed(2)) - Number(Number(total).toFixed(2));
    },
    amountTotal() {
      return this.payments.reduce((acc, el) => acc += Number(el.amount), 0);
    },
    hasPaymentCash() {
      return !!this.payments.find(el => el.type === 'EFECT');
    },
    availableMethods() {
      return this.methods.filter(method => {
        if (this.payments.find(payment => payment.type === method.code)) {
          return null
        } else {
          return method;
        }
      });
    },
    ...mapState({
      methods: state => state.main.paymentTypes
    }),
    canAddPayment() {
      if(this.type === 'CTCIA') {
        const {authorizer, authorization_code} = this.data;
        return !!authorizer && !!authorization_code;
      } else {
        return this.remaining > 0 && this.total !== 0;
      }
    },
    isForeignCoin() {
      return this.methods.filter(method => {
        return method.coin.id > 1 && this.type === method.code
      }).length > 0;
    },
    rateExchange() {
      if(this.isForeignCoin && this.amount &&this.foreignImport > 0){
        return (this.amount / this.foreignImport).toFixed(2);
      }
      return 0;
    }
  },
  mounted() {
    this.prepareComponent();
  },
  methods: {
    prepareComponent() {
      this.amount = this.totalFixed;
    },
    onPaid() {
      this.$buefy.dialog.confirm({
        title: 'Deleting account',
        message: `¿Estas seguro de aplicar el cobro por <b>${this.totalFixed}</b>?`,
        confirmText: 'Aplicar Cobro',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => {
          this.$emit('paid', this.data);
          this.$store.dispatch('order/payOrder', true)
          this.$parent.close();
        }
      })
    },
    onPayment() {
      const limit = this.remaining;
      if(this.amount > limit || this.amount < 0){
        this.$toast.error('No se puede ingresar esa cantidad');
        return;
      }
      const {type, amount, last_four, references, rateExchange, foreignImport} = this;
      let payment = {
        type, amount, last_four, references, method: (this.methods.filter(method => {
          return this.type === method.code
        }))[0],
        rate_exchange:rateExchange, foreign_import: foreignImport,
      };

      if(this.type === 'CTCIA') {
        const {authorization_code, authorizer} = this.data;
        this.checkIfValidCode(authorization_code, authorizer)
        .then(data => {
          this.$store.dispatch('order/orderPayments/addPayment', payment)
          this.prepareCash();
        });
      } else {
        const result = this.$alfalab.isValidPayment(payment)
        if(result.status){
          this.$store.dispatch('order/orderPayments/addPayment', payment)
          this.prepareCash();
        }else{
          this.$toast.error(result.message);
        }
      }


    },
    checkIfValidCode(code, authorizer) {
      this.$loader.show();
      return this.$http.get(`complimentary/${code}`, {
        params: {
          authorizer
        }
      }).then(data => {
        this.$loader.hide();
      }).catch(({response}) => {
        let data = response.data || {};
        this.$toast.error(data.message);
        this.$loader.hide();
        throw data;
      });
    },
    onDeleteItem(index) {
      this.$store.dispatch('order/orderPayments/deletePayment', index)
      this.prepareCash();
    },
    prepareCash() {
      this.amount = this.remaining;
      this.type = 'EFECT';
      this.foreignImport = 0;
    },
    onUpdatePaid(){
      this.$buefy.dialog.confirm({
        title: 'Actualizar orden',
        message: '¿Estas seguro que quieres actualizar los pagos de está orden?',
        confirmText: 'Si, actualizar',
        cancelText: 'No, cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          this.$loader.show();

          this.$emit('update-payments')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
