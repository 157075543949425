<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Búsqueda de orden</p>
    </header>
    <section class="modal-card-body">
      <div class="block">
        <b-field label="Tipo de busqueda" label-position="" v-if="!onlyQr">
          <b-field>
            <b-radio v-model="type"
                     name="name"
                     v-if="!onlyQr"
                     native-value="order">
              Número General
            </b-radio>
            <b-radio v-model="type"
                     name="name"
                     v-if="!onlyQr"
                     native-value="local">
              Número Local
            </b-radio>
            <b-radio v-model="type"
                     name="name"
                     v-if="!onlyQr"
                     native-value="medical_pass">
              Pase Médico
            </b-radio>
            <b-radio v-model="type"
                     name="name"
                     v-if="!onlyQr"
                     native-value="medical_pass_imss">
              Autodromo
            </b-radio>
            <b-radio v-model="type"
                     name="name"
                     v-if="!onlyQr"
                     native-value="quotation">
              <span>Tienda Virtual</span>
            </b-radio>
          </b-field>
        </b-field>
      </div>
      <b-field grouped
               v-if="!onlyQr"
               v-show="type === 'order'">
        <b-field label="Año"
        >
          <b-select v-model="year">
            <option
                v-for="item in years"
                :value="item.value"
                :key="item.value">
              {{ item.text }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Número de orden general">
          <b-input
              v-model="search"
              ref="order_type_input"
              placeholder="789649"
              @keypress.enter.native="onSearch"
          >
          </b-input>
        </b-field>
      </b-field>
      <b-field label="No. Local"
               v-show="type === 'local'">
        <b-input v-model="params.local"
                 ref="local_type_input"
                 @keypress.enter.native="onSearch"
        >
        </b-input>
      </b-field>
      <b-field label="Folio"
               v-show="type === 'medical_pass'">
        <b-input v-model="folio"
                 ref="medical_pass_type_input"
                 @keypress.enter.native="onSearch"
        >
        </b-input>
      </b-field>
      <b-field label="Folio"
               v-show="type === 'medical_pass_imss'">
        <b-input v-model="folio"
                 ref="medical_pass_imss_type_input"
                 @keypress.enter.native="onSearch"
        >
        </b-input>
      </b-field>
      <b-field :label="onlyQr ? 'QR' : 'Folio'"
               v-show="type === 'quotation'">
        <b-input v-model="folio"
                 ref="quotation_type_input"
                 @keypress.enter.native="onSearch"
        >
        </b-input>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Cerrar</button>
      <button class="button is-primary" @click="onSearch">
        <b-icon icon="search"></b-icon>
        <span>Buscar</span>
      </button>
    </footer>
  </div>
</template>

<script>
const date = new Date();
const year = date.getFullYear();

export default {
  name: "SearchModalOrder",
  props: {
    onlyQr: {type: Boolean, default: false}
  },
  data() {
    return {
      order: '',
      search: '',
      apiUrl: '',
      type: 'order',
      folio: '',
      params: {
        local: null,
        branch_office: this.$auth.user().active_branch_office
      },
      loading: false,
    }
  },
  watch: {
    type(val) {
      let input = `${val}_type_input`;
      this.$refs[input].focus();
    }
  },
  computed: {
    years() {
      return this.$store.state.main.years || [];
    },
    year: {
      set(value) {
        this.$store.dispatch('setDefaultYear', value);
      },
      get() {
        return this.$store.state.main.year || '1';
      }
    },
  },
  mounted() {
    if(this.onlyQr){
      this.type = 'quotation';
    }
    if( this.$refs.order_type_input){
      this.$refs.order_type_input.focus();
    }
  },
  methods: {
    async onSearch() {
      if (! this.loading) {
        this.$loader.show();
        this.loading = true;
        if (this.type === 'order') {
          await this.searchOrder();
        }
        else if (this.type === 'local') {
          await this.searchOrderWithParams();
        } else if (this.type === 'quotation'){
          await this.searchQuotation();
        } else {
          await this.searchFolio();
        }
        this.loading = false;
        this.$loader.hide();
      }
    },
    searchOrderWithParams() {
      this.apiUrl = 'orders';
      return this.getAll(this.params).then(({data}) => {
        if (data.length) {
          this.$parent.$parent.$emit('searchOrder', data[0]);
          this.$parent.close();
        }
      }, error => {
        this.$toast.error('Sin resultados');
      });
    },
    searchOrder() {
      this.order = String(this.year) + this.search.padStart(7);
      this.apiUrl = 'orders';
      return this.getData(this.order).then(({data = {}}) => {
        this.$parent.$parent.$emit('searchOrder', data);
        this.$parent.close();
      }, data => {
        this.$toast.error(data.message);
      });
    },
    searchFolio() {
      this.apiUrl = this.type === 'medical_pass' ? 'medical-pass' : 'medical-pass-imss';
      return this.getData(this.folio).then(({data = {}}) => {
        this.$parent.$parent.$emit('searchOrder', data);
        this.$parent.$parent.$emit('showMedicalPassInfo', data);
        this.$parent.close();
      }, data => {
        this.$toast.error(data.message);
      });
    },
    searchQuotation() {
      this.apiUrl = 'folio/quotation';
      return this.getData(this.folio).then(({data = {}}) => {
        this.$parent.$parent.$emit('searchOrder', data);
        this.$parent.close();
      }, data => {
        this.$toast.error(data.message);
      });
    }
  }
}
</script>

<style scoped>

</style>
