<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Información de Lapifan</p>
      <button
          type="button"
          class="delete"
          @click="$parent.close()"/>
    </header>
    <section class="modal-card-body">
      <div class="content">
        <div class="columns is-multiline">
          <div class="column is-12">
            <ul>
              <li><strong>Paciente: </strong>{{patient.full_name}}</li>
              <li><strong>No. Membresía: </strong>{{patient.lapifan_active.item.code}}</li>
              <li><strong>Fecha de caducidad: </strong>{{patient.lapifan_active.item.vigency_at}}</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button native-type="button" type="is-primary" @click="$parent.close()">Cerrar</b-button>
      <b-button native-type="button" type="is-default" @click="onSendWelcomeMail">Reenviar correo de bienvenida</b-button>
    </footer>
  </div>
</template>

<script>

export default {
  name: "LapifanModal",
  props: {
    patient: Object
  },
  methods: {
    onSendWelcomeMail(){
      const id = this.patient.lapifan_active.item.id
      this.$loader.show();
      this.$http.post(`/lapifan/mail/${id}`).then(() => {
        this.$toast.success('Correo enviado con éxito');
      }).finally(() => {
        this.$loader.hide();
      })
    }
  }
}

</script>


<style scoped>

</style>