import { render, staticRenderFns } from "./FormGetStats.vue?vue&type=template&id=216fd449&scoped=true"
import script from "./FormGetStats.vue?vue&type=script&lang=js"
export * from "./FormGetStats.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "216fd449",
  null
  
)

export default component.exports