<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Seleccion de diagnosticos</p>
    </header>
    <section class="modal-card-body">
      <b-table
          :data="data"
          :columns="columns"
          :selected.sync="selected"
          :paginated="true"
          :per-page="filters.perPage"
          focusable
          ref="diagnostics_table"
          searchable
          @dblclick="onSelect"
          :current-page="filters.page"
          :total="total"
          sticky-header
          height="70vh"
          :backend-pagination="true"
          :backend-filtering="true"
          @page-change="(page) => {this.filters = Object.assign(this.filters, {page: page}); this.prepareComponent()}"
          @keypress.enter.native="onEnterClickTable"
          @keyup.37.native="onKeyPressLeft"
          @keyup.39.native="onKeyPressRight"
          @filters-change="onFiltersChange"
      >
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon
                    icon="frown"
                    size="is-large">
                </b-icon>
              </p>
              <p>Lo sentimos no hay ningún diagnostico que coincida con tu busqueda.</p>
            </div>
          </section>
        </template>
      </b-table>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Cerrar</button>
      <button class="button is-primary" :disabled="data.length < 1" type="button" @click="onSelect(selected)">Seleccionar</button>
    </footer>
  </div>
</template>

<script>
export default {
  name: "ModalSelectDiagnostic",
  props: {
    index: Number
  },
  data() {
    return {
      data: [],
      currentPage: 1,
      filters: {
        perPage: 10,
        currentPage: 1
      },
      total: 0,
      columns: [
        {
          field: 'code',
          label: 'Código',
          searchable: true,
        },
        {
          field: 'name',
          label: 'Nombre',
          searchable: true,
        },
      ],
      selected: {}
    }
  },
  mounted() {
    this.prepareComponent();
  },
  methods: {
    prepareComponent(){
      this.$loader.show();
      this.$http.get('/cie10-catalog', {
        params: this.filters
      }).then(({data}) => {
        this.data = data.data;
        this.total = data.meta.total;
      }).catch(errors => {
        this.$alfalab.showError(errors);
      }).finally(() => {
        this.$loader.hide();
      });
    },
    onSelect(data) {
      this.$parent.close();
      this.$store.dispatch('order/setDiagnostic', {value: data, pos: this.index})
    },
    onEnterClickTable() {
      this.onSelect(this.selected);
    },
    onKeyPressLeft() {
      if (this.data.length > this.perPage) {
        if (this.currentPage > 1) {
          this.currentPage -= 1;
        }
      }
    },
    onKeyPressRight() {
      if (this.data.length > this.perPage) {
        let pages = Math.ceil(this.data.length / this.perPage);
        if (this.currentPage < pages) {
          this.currentPage += 1;
        }
      }
    },
    onFiltersChange(params){
      this.filters = Object.assign(this.filters, params);
      this.prepareComponent();
    }
  }
}
</script>

<style scoped>

</style>
