<template>
    <div >
        <div class="column is-12">
            <b-field label-position="on-border"
                     label="Nombre(s)">
                <b-input
                        readonly
                        v-model="data.name"
                        placeholder="Nombre(s)"
                        required>
                </b-input>
            </b-field>
        </div>
        <div class="column is-6">
            <b-field label-position="on-border"
                     label="Apellido Paterno">
                <b-input
                        readonly
                        v-model="data.last_name"
                        placeholder="Apellido Paterno"
                        required>
                </b-input>
            </b-field>
        </div>
        <div class="column is-6">
            <b-field label-position="on-border"
                     label="Apellido Materno">
                <b-input
                        readonly
                        v-model="data.other_name"
                        placeholder="Apellido Materno"
                        required>
                </b-input>
            </b-field>
        </div>
        <div class="column is-6">
            <b-field label-position="on-border"
                     label="Fecha de Nacimiento">
                <b-datepicker
                        readonly
                        @input="onSetBirthday"
                        :value="date"
                        :focusable="false"
                        placeholder="Selecciona una fecha"
                        required>
                </b-datepicker>
            </b-field>
        </div>
        <div class="column is-6">
            <b-field label="Género" label-position="on-border">
                <b-field>
                    <div class="block">
                        <b-radio v-model="data.gender"
                                 name="gender"
                                 readonly
                                 native-value="male">
                            Masculino
                        </b-radio>
                        <b-radio v-model="data.gender"
                                 name="gender"
                                 readonly
                                 native-value="female">
                            Femenino
                        </b-radio>
                    </div>
                </b-field>
            </b-field>
        </div>
        <div class="column is-6">
            <b-field label="Teléfono" label-position="on-border">
                <b-input type="tel"
                         readonly
                         v-model="data.phone"
                         placeholder="55555555"></b-input>
            </b-field>
        </div>
        <div class="column is-6">
            <b-field label="Correo" label-position="on-border">
                <b-input type="email"
                         readonly
                         v-model="data.email"
                         placeholder="Correo electrónico"></b-input>
            </b-field>
        </div>
        <!--                    <div class="column is-6">-->
        <!--                        <b-field label="Clave" label-position="on-border">-->
        <!--                            <b-input-->
        <!--                                     placeholder="Clave"></b-input>-->
        <!--                        </b-field>-->
        <!--                    </div>-->
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "FormPatientFolioImss",
        props: {
            data: Object,
            errors: Object,
        },
        computed: {
            date() {
                return this.data.birthday ? new Date(this.data.birthday) : null;
            }
        },
        mounted() {
        },
        methods: {
            onSetBirthday(val = null) {
                this.data.birthday = this.$moment(val || new Date()).format();
            }
        }
    }
</script>

<style scoped>

</style>