<template>
  <div class="marquee">
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: "MarqueeText",
  props: {
    message: String
  }
}
</script>

<style scoped>
.marquee {
  line-height: 50px;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 20s linear infinite;
}
@keyframes marquee {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}
</style>
