<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Registrar firma</p>
      <button
          type="button"
          class="delete"
          @click="$parent.close()"/>
    </header>
    <section class="modal-card-body">
      <div class="canvas-area">
        <canvas
            ref="canvas"
              @mousedown="handleMouseDown"
              @touchstart="handleMouseDown"
              @mouseup="handleMouseUp"
              @touchend="handleMouseUp"
              @mousemove="handleMouseMove"
              @touchmove="handleMouseMove"
            class="canvas" height="250px" width="500px"></canvas>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button native-type="button" type="is-primary" @click="handleSave">Guardar</b-button>
      <b-button native-type="button" type="is-default" @click="$parent.close()">Cancelar</b-button>
      <b-button native-type="button" type="is-default is-white" @click="handleClear">Limpiar</b-button>
    </footer>
  </div>
</template>

<script>
export default {
    name: "OrderSignDocuments",
    data() {
        return {
            context: null,
            isPress: false
        }
    },
    mounted() {
        this.context = this.$refs.canvas.getContext('2d');
    },
    methods: {
        handleMouseMove(event) {
          //  console.log(event);
            let x,y = 0;
            if(event.type === 'mousemove'){
                x = event.pageX - event.currentTarget.offsetLeft - event.currentTarget.parentElement.parentElement.parentElement.parentElement.offsetLeft;
                y = event.pageY - event.currentTarget.offsetTop - event.currentTarget.parentElement.parentElement.parentElement.parentElement.offsetTop;
            }
            else{
            //    console.log({pageX: event.pageX, pageY: event.pageY},  {currentTargetOffesetLeft:event.currentTarget.offsetLeft});
                x = event.touches[0].clientX - event.currentTarget.offsetLeft - event.currentTarget.parentElement.parentElement.parentElement.parentElement.offsetLeft;
                y = event.touches[0].clientY - event.currentTarget.offsetTop - event.currentTarget.parentElement.parentElement.parentElement.parentElement.offsetTop;
            }

            if (this.isPress) {
                this.context.lineTo(x, y);
                this.context.stroke();
            }
        },
        handleMouseUp(event) {
            this.isPress = false
            //console.log('se solto')
        },
        handleMouseDown(event) {
            //console.log(event.currentTarget);
            let x,y = 0;
            if(event.type === 'mousedown') {
                x = event.pageX - event.currentTarget.offsetLeft - event.currentTarget.parentElement.parentElement.parentElement.parentElement.offsetLeft;
                y = event.pageY - event.currentTarget.offsetTop - event.currentTarget.parentElement.parentElement.parentElement.parentElement.offsetTop;
            }else{
                x = event.touches[0].clientX - event.currentTarget.offsetLeft - event.currentTarget.parentElement.parentElement.parentElement.parentElement.offsetLeft;
                y = event.touches[0].clientY - event.currentTarget.offsetTop - event.currentTarget.parentElement.parentElement.parentElement.parentElement.offsetTop;
            }
            this.context.beginPath();
            this.context.moveTo(x, y);
            this.isPress = true;
            //console.log('se activo')
        },
        handleSave() {
            this.context.closePath();
            const data = this.$refs.canvas.toDataURL("image/png");
            const orderNew = this.$store.state.order.order_new;
            this.$store.dispatch('order/setPatientSign', data);
            if (orderNew) {
             //   console.log('enviando evento')
                this.$emit('order-update-patient-sign');
                this.$parent.close();
            } else {
                this.$emit('close-order-documents')
                this.$parent.close();
            }
        },
        handleClear() {
            this.context.closePath();
            this.context.beginPath();
            this.context.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
        }
    }
}
</script>

<style scoped>
.canvas-area {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 250px;
  align-items: center;
}
.canvas {
  border: 5px solid black;
  height: 250px;
}
</style>