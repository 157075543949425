<template>
    <div>
        <div class="section-title">
            <h3>Toma y Salida de muestras</h3>
            <router-link class="button is-info is-rounded ml-auto"
                          v-if="$alfalab.enableTomaMuestraAction($auth.user())"
                         :to="{name:'CreateLot', params: {type: 1}}">
                Registrar Toma de Muestra
            </router-link>
            <router-link class="button is-primary is-rounded ml-auto"
                         :to="{name:'CreateLot', params: {type:2}}">
                Registrar Salida
            </router-link>
        </div>

        <div class="container">
            <div class="notification">
                <b-select v-model="params.perPage" :disabled="!isPaginated" @input="getData">
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
					<option value="200">200</option>
                </b-select>
            </div>
        </div>
        <div class="activity-items">
            <b-table
                :data="data"
                striped
                :paginated="isPaginated"
                :per-page="params.perPage"
                :backend-pagination="true"
                :current-page.sync="params.page"
                :total="total"
                @change="getData"
                @page-change="getData"
                :loading="loading"
            >

                <b-table-column label="Acciones" v-slot="props">
                    <router-link :to="{ name: 'ShowLot', params: {id: props.row.id} }" :exact="true">
                        <span>Detalle</span>
                    </router-link>
                </b-table-column>

                <b-table-column field="branch_office" label="Sucursal" v-slot="props">
                    {{ props.row.branch_office.name }}
                </b-table-column>

                <b-table-column field="id" label="Lote" v-slot="props">
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column field="type" label="Tipo" v-slot="props">
                    {{ props.row.type === 1 ? 'Entrada' : 'Salida'  }}
                </b-table-column>

                <b-table-column field="date" label="Fecha" v-slot="props">
                    {{ props.row.date }}
                </b-table-column>

            </b-table>
        </div>
    </div>
</template>

<script>
export default {
    name: "Index",
    props: {},
    data() {
        return {
            loading: false,
            data: [],
            isPaginated: true,
            total: 0,
            params: {
                perPage: 25,
                page: 1,
            },
            apiUrl: 'lots',
            columns: [
                {
                    field: 'actions',
                    label: 'Acciones'
                },
                {
                    field: 'branch_office',
                    label: 'Sucursal',
                },
            ]
        }
    },
    mounted() {
        this.prepareComponent();
    },
    methods: {
        prepareComponent() {
            this.getData();
            console.log(this.$auth.user().active_branch_office)
        },
        async getData() {
            this.loading = true;
            await this.getAll(this.params)
                .then(({data = [], meta = {}}) => {
                    this.data = data;
                    this.total = meta.total || 0;
                }, data => {

                });
            this.loading = false;
        }

    }
}
</script>

<style scoped>

</style>
